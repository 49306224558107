export default {
  '750': {
    ml: '37',
    line: '750',
    sdcs: ['64', '60'],
    id: '024',
    descr: 'ΑΓ. ΑΝΑΡΓΥΡΟΙ - ΣΤ. ΚΑΤΩ ΠΑΤΗΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. ANARGYROI - ST. KATO PATHSIA',
    routes: ['2640'],
  },
  '799': {
    ml: '1',
    line: '799',
    sdcs: ['54', '59', '60'],
    id: '224',
    descr: 'ΚΑΙΣΑΡΙΑΝΗ - ΕΛ. ΒΕΝΙΖΕΛΟΥ',
    descr_en: 'KAISARIANI - EL. VENIZELOY',
    routes: ['1754', '1866', '1880'],
  },
  '802': {
    ml: '168',
    line: '802',
    sdcs: ['54', '59', '60'],
    id: '405',
    descr: 'ΣΤ. ΧΑΛΑΝΔΡΙ - ΜΕΛΙΣΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. CHALANDRIOU - MELISSIA',
    routes: ['2617'],
  },
  '804': {
    ml: '3',
    line: '804',
    sdcs: ['54', '59', '60'],
    id: '036',
    descr: 'ΣΤ. ΚΑΤΕΧΑΚΗ -ΣΤ. ΠΑΝΟΡΜΟΥ-ΓΑΛΑΤΣΙ-ΚΥΨΕΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KATECHAKI - KYPSELI',
    routes: ['2597', '2893'],
  },
  '805': {
    ml: '4',
    line: '805',
    sdcs: ['54', '59', '60'],
    id: '732',
    descr: 'ΑΓ. ΦΑΝΟΥΡΙΟΣ -ΑΚΑΔΗΜΙΑ - Ζ. ΠΗΓΗ',
    descr_en: 'AG. FANOYRIOS - Z. PIGI',
    routes: ['1762', '1763'],
  },
  '806': {
    ml: '5',
    line: '806',
    sdcs: ['81'],
    id: 'Χ93',
    descr: 'ΣΤ.ΥΠΕΡ.ΛΕΩΦ.ΚΗΦΙΣΟΥ - ΑΕΡΟΛΙΜΕΝΑΣ ΑΘΗΝΩΝ(EXPRESS)',
    descr_en: 'ST.YPER.LEOF.KIFISOU - AEROLIMENAS ATHINON (EXPRESS)',
    routes: ['2380', '2381'],
  },
  '807': {
    ml: '143',
    line: '807',
    sdcs: ['54', '59', '60'],
    id: 'Γ16',
    descr: 'ΠΛ.ΚΟΥΜΟΥΝΔΟΥΡΟΥ - ΑΣΠΡΟΠΥΡΓΟΣ',
    descr_en: 'PL.KOUMOUNDOUROU - ASPROPYRGOS',
    routes: ['1768', '1769'],
  },
  '808': {
    ml: '7',
    line: '808',
    sdcs: ['54', '59', '60'],
    id: '813',
    descr: 'ΑΒΕΡΩΦ - ΠΡΟΥΣΣΗΣ',
    descr_en: 'AVEROF - PROYSSIS',
    routes: ['1770', '1771', '2892'],
  },
  '810': {
    ml: '144',
    line: '810',
    sdcs: ['54', '59', '60'],
    id: '863',
    descr: 'ΕΛΕΥΣΙΝΑ - ΘΡΙΑΣΙΟ ΝΟΣ. - ΜΑΝΔΡΑ ',
    descr_en: 'ELEYSINA - THRIASIO NOS. - MANDRA',
    routes: ['2843', '2844'],
  },
  '812': {
    ml: '144',
    line: '812',
    sdcs: ['54', '59', '60'],
    id: '817',
    descr: 'ΕΛΕΥΣΙΝΑ - ΜΑΝΔΡΑ',
    descr_en: 'ELEYSINA - MANDRA',
    routes: ['2841', '2842'],
  },
  '813': {
    ml: '144',
    line: '813',
    sdcs: ['54', '59', '60'],
    id: '881',
    descr: 'ΕΛΕΥΣΙΝΑ - ΑΣΠΡΟΠΥΡΓΟΣ - ΡΟΥΠΑΚΙ',
    descr_en: 'ELEYSINA - ASPROPYRGOS - ROYPAKI',
    routes: ['1779', '2902'],
  },
  '814': {
    ml: '144',
    line: '814',
    sdcs: ['54'],
    id: '864',
    descr: 'ΕΛΕΥΣΙΝΑ - ΒΙΟΜΗΧΑΝΙΚΗ ΠΕΡΙΟΧΗ ΜΑΓΟΥΛΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ELEYSINA - VIOMICHANIKI ZONI MAGOYLAS',
    routes: ['2293'],
  },
  '816': {
    ml: '11',
    line: '816',
    sdcs: ['54', '59', '60'],
    id: '035',
    descr: 'ΑΝΩ ΚΥΨΕΛΗ - ΠΕΤΡΑΛΩΝΑ - ΤΑΥΡΟΣ',
    descr_en: 'ANO KYPSELI - PETRALONA - TAVROS',
    routes: ['2948', '2949', '2953'],
  },
  '817': {
    ml: '137',
    line: '817',
    sdcs: ['54', '59', '60'],
    id: '025',
    descr: 'ΙΠΠΟΚΡΑΤΟΥΣ - ΠΡΟΦΗΤΗ ΔΑΝΙΗΛ',
    descr_en: 'IPPOKRATOUS - PROFITI DANIIL',
    routes: ['1797', '1798'],
  },
  '818': {
    ml: '137',
    line: '818',
    sdcs: ['54', '59', '60'],
    id: '026',
    descr: 'ΙΠΠΟΚΡΑΤΟΥΣ - ΒΟΤΑΝΙΚΟΣ',
    descr_en: 'IPPOKRATOUS - VOTANIKOS',
    routes: ['1799', '1800'],
  },
  '819': {
    ml: '16',
    line: '819',
    sdcs: ['54', '59', '60'],
    id: '049',
    descr: 'ΠΕΙΡΑΙΑΣ - ΟΜΟΝΟΙΑ',
    descr_en: 'PEIRAIAS - OMONOIA',
    routes: ['1802', '2995'],
  },
  '820': {
    ml: '144',
    line: '820',
    sdcs: ['54', '59', '60'],
    id: '861',
    descr: 'ΕΛΕΥΣΙΝΑ - ΜΑΝΔΡΑ (ΟΙΚΙΣΜΟΣ ΤΙΤΑΝ)',
    descr_en: 'ELEYSINA - MANDRA (OIKISMOS TITAN)',
    routes: ['1814', '1815', '3063', '3064'],
  },
  '821': {
    ml: '136',
    line: '821',
    sdcs: ['54', '59', '60'],
    id: '022',
    descr: 'Ν. ΚΥΨΕΛΗ - ΜΑΡΑΣΛΕΙΟΣ   ',
    descr_en: 'NEA KYPSELI - MARASLEIOS',
    routes: ['1804', '1805'],
  },
  '822': {
    ml: '18',
    line: '822',
    sdcs: ['54', '59', '60'],
    id: '605',
    descr: 'ΠΕΡΙΣΣΟΣ - ΑΝΩ ΠΑΤΗΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PERISSOS - ANO PATISIA',
    routes: ['1806', '3045'],
  },
  '823': {
    ml: '140',
    line: '823',
    sdcs: ['54', '59', '60'],
    id: '704',
    descr: 'ΚΑΜΑΤΕΡΟ - ΠΛ. ΑΓ. ΑΝΑΡΓΥΡΩΝ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KAMATERO - PL. AG. ANARGYRON',
    routes: ['1808'],
  },
  '824': {
    ml: '20',
    line: '824',
    sdcs: ['54', '59', '60'],
    id: '057',
    descr: 'ΟΜΟΝΟΙΑ - ΛΟΦΟΣ ΣΚΟΥΖΕ (ΚΥΚΛΙΚΗ)',
    descr_en: 'OMONOIA - LOFOS SKOUZE',
    routes: ['1810'],
  },
  '825': {
    ml: '21',
    line: '825',
    sdcs: ['54', '59'],
    id: '060',
    descr: 'ΜΟΥΣΕΙΟ - ΑΚΑΔΗΜΙΑ - ΛΥΚΑΒΗΤΤΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'MOUSEIO - AKADIMIA - LYKAVITTOS',
    routes: ['2990', '2991'],
  },
  '826': {
    ml: '22',
    line: '826',
    sdcs: ['55', '69', '59'],
    id: '100',
    descr: 'ΠΛ. ΚΟΥΜΟΥΝΔΟΥΡΟΥ - ΚΟΛΩΝΑΚΙ - ΑΓΟΡΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PL. KOYMOYNDOYROY - KOLONAKI - AGORA',
    routes: ['1813'],
  },
  '827': {
    ml: '144',
    line: '827',
    sdcs: ['54', '59', '60'],
    id: '855',
    descr: 'ΨΑΡΙ - ΑΣΠΡΟΠΥΡΓΟΣ - ΛΟΦΟΣ ΚΥΡΙΛΛΟΥ',
    descr_en: 'PSARI - ASPROPYRGOS - LOFOS KYRILLOY',
    routes: ['2819', '2821'],
  },
  '830': {
    ml: '89',
    line: '830',
    sdcs: ['54', '59', '60'],
    id: 'Α11',
    descr: 'ΠΛ. ΒΑΘΗ - ΙΛΙΟΝ - ΠΕΤΡΟΥΠΟΛΗ',
    descr_en: 'PL. VATHI - ILION - PETROYPOLI',
    routes: ['1833', '1834'],
  },
  '831': {
    ml: '24',
    line: '831',
    sdcs: ['54', '59', '60'],
    id: '046',
    descr: 'ΜΟΥΣΕΙΟ - ΕΛΛΗΝΟΡΩΣΩΝ',
    descr_en: 'MOUSEIO - ELLINOROSON',
    routes: ['1820', '1821', '2954'],
  },
  '832': {
    ml: '25',
    line: '832',
    sdcs: ['54', '59', '60'],
    id: 'Γ12',
    descr: 'ΚΑΜΑΤΕΡΟ - ΑΓ. ΝΙΚΟΛΑΟΣ - ΙΛΙΟΝ - ΠΟΛΥΤΕΧΝΕΙΟ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KAMATERO - AG. NIKOLAOS - ILION - POLYTECHNEIO',
    routes: ['1822'],
  },
  '833': {
    ml: '26',
    line: '833',
    sdcs: ['54', '59', '60'],
    id: 'Α8',
    descr: 'ΠΟΛΥΤΕΧΝΕΙΟ - Ν. ΙΩΝΙΑ - ΜΑΡΟΥΣΙ',
    descr_en: 'POLYTEXNEIO - N. IONIA - MAROUSI',
    routes: ['1824', '1825'],
  },
  '834': {
    ml: '27',
    line: '834',
    sdcs: ['54', '59', '60'],
    id: 'Β12',
    descr: 'ΜΑΡΝΗ - ΑΝΩ ΛΙΟΣΙΑ',
    descr_en: 'MARNI - ANO LIOSIA',
    routes: ['1827', '2877', '3056', '3057'],
  },
  '835': {
    ml: '28',
    line: '835',
    sdcs: ['54', '59', '60'],
    id: '748',
    descr: 'ΧΡΥΣΟΥΠΟΛΗ - ΑΝΘΟΥΠΟΛΗ - ΣΤ. ΠΕΡΙΣΤΕΡΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'CHRYSOYPOLI - ANTHOYPOLI - ST. PERISTERI',
    routes: ['1828'],
  },
  '836': {
    ml: '29',
    line: '836',
    sdcs: ['81'],
    id: '560',
    descr: 'ΣΤ. ΚΗΦΙΣΙΑ - ΜΟΡΤΕΡΟ - Ν. ΕΡΥΘΡΑΙΑ',
    descr_en: 'ST. KIFISIA - MORTERO - N. ERYTHRAIA',
    routes: ['1829', '1830'],
  },
  '837': {
    ml: '30',
    line: '837',
    sdcs: ['54', '59', '60'],
    id: '719',
    descr: 'ΚΗΠΟΥΠΟΛΗ - ΠΑΛΑΤΙΑΝΗ - ΣΤ. ΛΑΡΙΣΗΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KIPOYPOLI - PALATIANI - ST. LARISIS',
    routes: ['2882'],
  },
  '838': {
    ml: '31',
    line: '838',
    sdcs: ['54', '59', '60'],
    id: 'Γ10',
    descr: 'ΑΝΑΚΑΣΑ - ΑΓ. ΑΝΑΡΓΥΡΟΙ - ΠΛ. ΒΑΘΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANAKASA - AG. ANARGYROI - PL. VATHI',
    routes: ['1832', '2270'],
  },
  '839': {
    ml: '89',
    line: '839',
    sdcs: ['54', '59', '60'],
    id: 'Β11',
    descr: 'ΠΛ. ΒΑΘΗ - ΙΛΙΟΝ - ΠΕΤΡΟΥΠΟΛΗ',
    descr_en: 'PL. VATHI - ILION - PETROYPOLI',
    routes: ['1835', '1836'],
  },
  '840': {
    ml: '196',
    line: '840',
    sdcs: ['54', '59', '60'],
    id: 'Α15',
    descr: 'ΣΤ. ΛΑΡΙΣΗΣ - ΔΑΣΟΣ',
    descr_en: 'ST. LARISIS - DASOS',
    routes: ['1837', '2980'],
  },
  '841': {
    ml: '143',
    line: '841',
    sdcs: ['54', '59', '60'],
    id: '866',
    descr: 'ΑΣΠΡΟΠΥΡΓΟΣ - ΣΤ. ΑΓ. ΜΑΡΙΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ASPROPYRGOS - ST. AG. MARINA',
    routes: ['1839', '2803'],
  },
  '844': {
    ml: '39',
    line: '844',
    sdcs: ['54', '59', '60'],
    id: '892',
    descr: 'ΑΓ. ΒΑΡΒΑΡΑ - ΧΑΪΔΑΡΙ - ΑΓ. ΑΝΑΡΓΥΡΟΙ-ΙΛΙΟΝ',
    descr_en: 'AG. VARVARA - CHAIDARI - AG. ANARGYROI-ILION',
    routes: ['1849', '2858'],
  },
  '845': {
    ml: '232',
    line: '845',
    sdcs: ['54', '59', '60'],
    id: '731',
    descr: 'ΑΝΘΟΥΠΟΛΗ - ΣΤ. Μ. ΑΝΘΟΥΠΟΛΗ - ΑΤΤΙΚΟ ΝΟΣ. - ΔΑΣΟΣ',
    descr_en: 'ANTHOUPOLI - ST. M. ANTHOUPOLI - ATTIKO NOS. - DASOS',
    routes: ['1851', '2445'],
  },
  '846': {
    ml: '40',
    line: '846',
    sdcs: ['54', '59', '60'],
    id: '410',
    descr: 'Ο.Α.Κ.Α. - ΑΝΩ ΜΕΛΙΣΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'O.A.K.A. - ANO MELISSIA',
    routes: ['1963'],
  },
  '847': {
    ml: '41',
    line: '847',
    sdcs: ['54', '59', '60'],
    id: '227',
    descr: 'ΑΝΩ ΠΕΤΡΑΛΩΝΑ - ΑΓ. ΑΡΤΕΜΙΟΣ',
    descr_en: 'ANO PETRALONA - AG. ARTEMIOS',
    routes: ['1977', '1978'],
  },
  '849': {
    ml: '43',
    line: '849',
    sdcs: ['54', '59', '60'],
    id: '803',
    descr: 'ΠΕΙΡΑΙΑΣ - ΔΑΣΟΣ Β (ΜΕΣΩ ΙΕΡΑΣ ΟΔΟΥ)',
    descr_en: 'PEIRAIAS - DASOS Β (VIA IERA ODOS)',
    routes: ['1857', '1858'],
  },
  '851': {
    ml: '202',
    line: '851',
    sdcs: ['54', '59', '60'],
    id: '130',
    descr: 'ΠΕΙΡΑΙΑΣ - Ν. ΣΜΥΡΝΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEIRAIAS - NEA SMIRNI',
    routes: ['2812'],
  },
  '852': {
    ml: '44',
    line: '852',
    sdcs: ['54', '59', '60'],
    id: '711',
    descr: 'ΖΩΦΡΙΑ - ΑΤΤΙΚΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ZOFRIA - ATTIKI',
    routes: ['2876'],
  },
  '854': {
    ml: '64',
    line: '854',
    sdcs: ['54', '59', '60'],
    id: '871',
    descr: 'ΠΕΙΡΑΙΑΣ - ΕΛΕΥΣΙΝΑ (ΜΕΣΩ Λ. ΕΘΝ. ΑΝΤΙΣΤΑΣΕΩΣ)',
    descr_en: 'PEIRAIAS - ELEYSINA (VIA L. ETHN. ANTISTASEOS)',
    routes: ['2193', '2194'],
  },
  '855': {
    ml: '220',
    line: '855',
    sdcs: ['54', '59', '60'],
    id: '420',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΑΝΑΡΓΥΡΟΙ (ΜΕΣΩ ΚΗΦΙΣΟΥ)  (ΑΚΤΗ ΚΟΝΔΥΛΗ) ',
    descr_en: 'PEIRAIAS - AG. ANARGYROI (VIA KIFISOY) (AKTI KONDILI)',
    routes: ['1862', '1863'],
  },
  '856': {
    ml: '158',
    line: '856',
    sdcs: ['54', '59', '60'],
    id: '847',
    descr: 'ΝΕΑΠΟΛΗ - ΠΕΙΡΑΙΑΣ Β (ΚΥΚΛΙΚΗ)',
    descr_en: 'NEAPOLI - PEIRAIAS Β',
    routes: ['1865'],
  },
  '857': {
    ml: '153',
    line: '857',
    sdcs: ['54', '59', '60'],
    id: '825',
    descr: 'ΝΕΑΠΟΛΗ - ΑΓ. ΑΝΤΩΝΙΟΣ - ΠΕΙΡΑΙΑΣ Β (ΚΥΚΛΙΚΗ)',
    descr_en: 'NEAPOLI - AG. ANTONIOS - PEIRAIAS B ',
    routes: ['1868'],
  },
  '858': {
    ml: '50',
    line: '858',
    sdcs: ['54', '59', '60'],
    id: '826',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΜΗΝΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEIRAIAS - AG. MINAS',
    routes: ['1869'],
  },
  '861': {
    ml: '151',
    line: '861',
    sdcs: ['54', '59', '60'],
    id: '807',
    descr: 'ΑΝΩ ΚΟΡΥΔΑΛΛΟΣ - ΣΤ. ΜΕΤΡΟ ΑΙΓΑΛΕΩ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANO KORYDALLOS - ST. METRO AIGALEO',
    routes: ['1903'],
  },
  '862': {
    ml: '52',
    line: '862',
    sdcs: ['54', '59', '60'],
    id: '811',
    descr: 'ΧΑΪΔΑΡΙ - ΣΤ. ΑΓΙΑ ΜΑΡΙΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'CHAIDARI - ST. AGIA MARINA',
    routes: ['1910'],
  },
  '863': {
    ml: '64',
    line: '863',
    sdcs: ['54', '59', '60'],
    id: '814',
    descr: 'ΣΧΙΣΤΟ ΚΑΡΑΒΑΣ - ΠΕΙΡΑΙΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'SCHISTO KARAVAS - PEIRAIAS',
    routes: ['1870'],
  },
  '864': {
    ml: '152',
    line: '864',
    sdcs: ['54', '59', '60'],
    id: '809',
    descr: 'ΣΧΙΣΤΟ - ΚΟΡΥΔΑΛΛΟΣ - ΠΕΙΡΑΙΑΣ Α (ΚΥΚΛΙΚΗ)',
    descr_en: 'SCHISTO - KORYDALLOS - PEIRAIAS A ',
    routes: ['1871'],
  },
  '865': {
    ml: '55',
    line: '865',
    sdcs: ['54', '59', '60'],
    id: '830',
    descr: 'ΑΓ. ΒΑΡΒΑΡΑ - ΠΕΙΡΑΙΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. VARVARA - PEIRAIAS',
    routes: ['1872'],
  },
  '866': {
    ml: '56',
    line: '866',
    sdcs: ['54', '59', '60'],
    id: 'Α13',
    descr: 'ΟΜΟΝΟΙΑ - ΚΗΠΟΥΠΟΛΗ',
    descr_en: 'OMONOIA - KIPOYPOLI',
    routes: ['1873', '1874'],
  },
  '867': {
    ml: '57',
    line: '867',
    sdcs: ['54', '59', '60'],
    id: '723',
    descr: 'ΦΥΛΗ - ΑΝΩ ΛΙΟΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'FYLI - ANO LIOSIA',
    routes: ['2406', '3055'],
  },
  '868': {
    ml: '58',
    line: '868',
    sdcs: ['54', '59', '60'],
    id: '054',
    descr: 'ΠΕΡΙΣΣΟΣ - ΑΚΑΔΗΜΙΑ - ΜΕΤΑΜΟΡΦΩΣΗ',
    descr_en: 'PERISSOS - AKADIMIA METAMORFOSI',
    routes: ['1876', '1877'],
  },
  '871': {
    ml: '59',
    line: '871',
    sdcs: ['54', '59', '60'],
    id: 'Β1',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΝΩ ΓΛΥΦΑΔΑ',
    descr_en: 'PEIRAIAS - ANO GLYFADA',
    routes: ['1881', '1882', '2315', '2316'],
  },
  '872': {
    ml: '64',
    line: '872',
    sdcs: ['54', '59', '60'],
    id: '845',
    descr: 'ΠΕΙΡΑΙΑΣ - ΕΛΕΥΣΙΝΑ (ΜΕΣΩ ΘΗΒΩΝ)',
    descr_en: 'PEIRAIAS - ELEYSINA (VIA THIVON)',
    routes: ['1883', '1884'],
  },
  '873': {
    ml: '61',
    line: '873',
    sdcs: ['54', '59'],
    id: '829',
    descr: 'ΤΕΙ ΠΕΙΡΑΙΑ-ΣΤ.ΑΙΓΑΛΕΩ-ΤΕΙ ΑΘΗΝΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'TEI PEIRAIA-ST. METRO AIGALEO-TEI ATHINAS',
    routes: ['2356'],
  },
  '874': {
    ml: '62',
    line: '874',
    sdcs: ['54', '59', '60'],
    id: '831',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΙΓΑΛΕΩ',
    descr_en: 'PEIRAIAS - AIGALEO',
    routes: ['1886', '2988'],
  },
  '875': {
    ml: '63',
    line: '875',
    sdcs: ['54', '59', '60'],
    id: '852',
    descr: 'ΝΕΑΠΟΛΗ - ΣΤ. ΜΕΤΡΟ ΑΙΓΑΛΕΩ (ΚΥΚΛΙΚΗ)',
    descr_en: 'NEAPOLI - ST. METRO AIGALEO ',
    routes: ['1888'],
  },
  '877': {
    ml: '64',
    line: '877',
    sdcs: ['54', '59', '60'],
    id: '500',
    descr: 'ΠΕΙΡΑΙΑΣ - ΚΗΦΙΣΙΑ(ΠΛ.ΠΛΑΤΑΝΟΥ) (ΝΥΧΤΕΡΙΝΗ)',
    descr_en: 'PEIRAIAS - KIFISIA - (PL.PLATANOU) (NIGHT LINE)',
    routes: ['1889', '1890', '2783', '2900'],
  },
  '878': {
    ml: '153',
    line: '878',
    sdcs: ['54', '59', '60'],
    id: '824',
    descr: 'ΝΕΑΠΟΛΗ - ΑΓ. ΑΝΤΩΝΙΟΣ - ΠΕΙΡΑΙΑΣ Α (ΚΥΚΛΙΚΗ)',
    descr_en: 'NEAPOLI - AG. ANTONIOS - PEIRAIAS A ',
    routes: ['1891'],
  },
  '879': {
    ml: '66',
    line: '879',
    sdcs: ['54', '59', '60'],
    id: '856',
    descr: 'ΑΙΓΑΛΕΩ - ΥΜΗΤΤΟΣ - ΔΑΦΝΗ',
    descr_en: 'AIGALEO - YMITTOS - DAFNI',
    routes: ['1892', '1893'],
  },
  '882': {
    ml: '68',
    line: '882',
    sdcs: ['54', '59', '60'],
    id: '906',
    descr: 'ΠΛ. ΚΑΡΑΪΣΚΑΚΗ - ΚΑΛΛΙΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PL. KARAΪSKAKI - KALLIPOLI',
    routes: ['1895'],
  },
  '883': {
    ml: '156',
    line: '883',
    sdcs: ['54', '59', '60'],
    id: '914',
    descr: 'ΟΜΟΝΟΙΑ - ΛΑΧΑΝΑΓΟΡΑ - ΠΑΛ. ΚΟΚΚΙΝΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'OMONOIA - LACHANAGORA - PAL. KOKKINIA',
    routes: ['1896'],
  },
  '884': {
    ml: '158',
    line: '884',
    sdcs: ['54', '59', '60'],
    id: '846',
    descr: 'ΝΕΑΠΟΛΗ - ΠΕΙΡΑΙΑΣ Α (ΚΥΚΛΙΚΗ)',
    descr_en: 'NEAPOLI - PEIRAIAS A ',
    routes: ['1897'],
  },
  '885': {
    ml: '71',
    line: '885',
    sdcs: ['54', '59', '60'],
    id: '300',
    descr: 'ΠΛ. ΚΑΡΑΪΣΚΑΚΗ - ΤΖΑΝΕΙΟ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PL. KARAΪSKAKI - TZANEIO ',
    routes: ['1898'],
  },
  '886': {
    ml: '72',
    line: '886',
    sdcs: ['54', '59', '60'],
    id: '750',
    descr: 'ΑΤΤΙΚΟ ΝΟΣΟΚΟΜΕΙΟ - ΣΤ. ΜΕΤΡΟ ΑΙΓΑΛΕΩ - ΝΙΚΑΙΑ',
    descr_en: 'ATTIKO NOSOKOMEIO - ST. METRO AIGALEO - NIKAIA',
    routes: ['1899', '1900', '2768'],
  },
  '887': {
    ml: '74',
    line: '887',
    sdcs: ['54', '59', '60'],
    id: '800',
    descr: 'ΝΙΚΑΙΑ - ΠΕΙΡΑΙΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'NIKAIA - PEIRAIAS ',
    routes: ['1901'],
  },
  '888': {
    ml: '151',
    line: '888',
    sdcs: ['54', '59', '60'],
    id: '806',
    descr: 'ΚΟΡΥΔΑΛΛΟΣ - ΣΤ. ΜΕΤΡΟ ΑΙΓΑΛΕΩ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KORYDALLOS - ST. METRO AIGALEO ',
    routes: ['1902'],
  },
  '889': {
    ml: '152',
    line: '889',
    sdcs: ['54', '59', '60'],
    id: '810',
    descr: 'ΣΧΙΣΤΟ - ΚΟΡΥΔΑΛΛΟΣ - ΠΕΙΡΑΙΑΣ Β (ΚΥΚΛΙΚΗ)',
    descr_en: 'SCHISTO - KORYDALLOS - PEIRAIAS Β',
    routes: ['1999'],
  },
  '890': {
    ml: '77',
    line: '890',
    sdcs: ['54', '68'],
    id: '819',
    descr: 'ΠΕΡΑΜΑ - ΝΕΚΡ. ΣΧΙΣΤΟΥ',
    descr_en: 'PERAMA - NEKR. SCHISTOY',
    routes: ['1904', '1905'],
  },
  '891': {
    ml: '78',
    line: '891',
    sdcs: ['54', '59', '60'],
    id: '820',
    descr: 'ΚΕΡΑΤΣΙΝΙ - ΣΤ. ΜΕΤΡΟ ΑΙΓΑΛΕΩ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KERATSINI - ST. METRO AIGALEO',
    routes: ['1906'],
  },
  '892': {
    ml: '73',
    line: '892',
    sdcs: ['81'],
    id: 'Χ96',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΕΡ/ΝΑΣ ΑΘΗΝΩΝ (EXPRESS)',
    descr_en: 'PEIRAIAS - AEROLIMENAS ATHINON (EXPRESS)',
    routes: ['3028', '3029'],
  },
  '894': {
    ml: '154',
    line: '894',
    sdcs: ['54', '59', '60'],
    id: '827',
    descr: 'ΠΕΙΡΑΙΑΣ - Γ ΝΕΚΡΟΤΑΦΕΙΟ - ΑΣΠΡΑ ΧΩΜΑΤΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEIRAIAS - C NEKROTAFEIO - ASPRA CHOMATA',
    routes: ['1911'],
  },
  '895': {
    ml: '154',
    line: '895',
    sdcs: ['54', '59', '60'],
    id: '828',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΣΠΡΑ ΧΩΜΑΤΑ - Γ ΝΕΚΡΟΤΑΦΕΙΟ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEIRAIAS - ASPRA CHOMATA - G NEKROTAFEIO',
    routes: ['1912'],
  },
  '899': {
    ml: '84',
    line: '899',
    sdcs: ['54', '59', '60'],
    id: '821',
    descr: 'ΑΓΙΑ ΑΝΑΣΤΑΣΙΑ - ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AGIA ANASTASIA - ST. AG. ANTONIOS',
    routes: ['1916'],
  },
  '900': {
    ml: '85',
    line: '900',
    sdcs: ['54', '59', '60'],
    id: '837',
    descr: 'ΑΝΩ ΑΓ. ΒΑΡΒΑΡΑ - ΣΤ. ΑΓΙΑ ΜΑΡΙΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANO AG. VARVARA - ST. AGIA MARINA',
    routes: ['2874', '2875'],
  },
  '903': {
    ml: '265',
    line: '903',
    sdcs: ['54', '59', '60'],
    id: 'Β15',
    descr: 'ΣΤ. ΛΑΡΙΣΗΣ - ΠΑΛΑΤΑΚΙ',
    descr_en: 'ST. LARISIS - PALATAKI',
    routes: ['2204', '2979'],
  },
  '904': {
    ml: '280',
    line: '904',
    sdcs: ['54', '59', '60'],
    id: '537',
    descr: 'ΑΧΑΡΝΑΙ - ΒΑΡΥΜΠΟΜΠΗ',
    descr_en: 'ACHARNAI - VARYMPOMPI',
    routes: ['1966', '1967'],
  },
  '905': {
    ml: '280',
    line: '905',
    sdcs: ['54', '59', '60'],
    id: '728',
    descr: 'ΑΧΑΡΝΑΙ- ΑΓ.ΠΕΤΡΟΣ- ΑΓ.ΙΩΑΝΝΗΣ-ΠΛΑΤΩΝΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ACHARNAI - PLATONAS ',
    routes: ['2584'],
  },
  '907': {
    ml: '280',
    line: '907',
    sdcs: ['54', '59', '60'],
    id: '733',
    descr: 'ΑΧΑΡΝΑΙ - ΑΝΩ ΛΙΟΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ACHARNAI - ANO LIOSIA ',
    routes: ['2226'],
  },
  '909': {
    ml: '280',
    line: '909',
    sdcs: ['54', '59', '60'],
    id: '752',
    descr: 'ΑΧΑΡΝΑΙ - ΑΓ. ΙΩΑΝΝΗΣ - ΑΓ. ΠΕΤΡΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ACHARNAI - AG. IOANNIS - AG. PETROS ',
    routes: ['2262', '2605'],
  },
  '911': {
    ml: '284',
    line: '911',
    sdcs: ['54', '59', '60'],
    id: '878',
    descr: 'ΑΧΑΡΝΑΙ - ΕΛΕΥΣΙΝΑ',
    descr_en: 'ACHARNAI - ELEYSINA',
    routes: ['2232', '2233'],
  },
  '912': {
    ml: '284',
    line: '912',
    sdcs: ['54', '59', '60'],
    id: '879',
    descr: 'ΑΧΑΡΝΑΙ - ΘΡΙΑΣΙΟ ΝΟΣΟΚΟΜΕΙΟ - ΕΛΕΥΣΙΝΑ',
    descr_en: 'ACHARNAI - THRIASIO NOSOKOMEIO - ELEYSINA',
    routes: ['2820', '2822'],
  },
  '913': {
    ml: '87',
    line: '913',
    sdcs: ['54', '59', '60'],
    id: '503',
    descr: 'ΖΗΡΙΝΕΙΟ - ΒΑΡΥΜΠΟΜΠΗ',
    descr_en: 'ZIRINEIO - VARYMPOMPI',
    routes: ['2188', '2189', '2327'],
  },
  '914': {
    ml: '87',
    line: '914',
    sdcs: ['54', '59', '60'],
    id: '507',
    descr: 'ΖΗΡΙΝΕΙΟ - ΡΟΔΟΠΟΛΗ - ΣΤΑΜΑΤΑ',
    descr_en: 'ZIRINEIO - RODOPOLI - STAMATA',
    routes: ['2890', '2891'],
  },
  '916': {
    ml: '87',
    line: '916',
    sdcs: ['54', '59', '60'],
    id: '536',
    descr: 'ΖΗΡΙΝΕΙΟ - ΔΙΟΝΥΣΟΣ',
    descr_en: 'ZIRINEIO - DIONYSOS',
    routes: ['2179', '2180'],
  },
  '917': {
    ml: '87',
    line: '917',
    sdcs: ['54', '59', '60'],
    id: '535',
    descr: 'ΖΗΡΙΝΕΙΟ - ΕΥΞΕΙΝΟΣ ΠΟΝΤΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ZIRINEIO - EYXEINOS PONTOS ',
    routes: ['2259'],
  },
  '918': {
    ml: '282',
    line: '918',
    sdcs: ['54', '59', '60'],
    id: '721',
    descr: 'ΑΧΑΡΝΑΙ - ΚΗΦΙΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ACHARNAI - KIFISIA ',
    routes: ['1944'],
  },
  '921': {
    ml: '149',
    line: '921',
    sdcs: ['54', '59', '60'],
    id: '124',
    descr: 'ΓΛΥΦΑΔΑ - ΑΓΙΑ ΤΡΙΑΔΑ - ΣΤ. ΕΛΛΗΝΙΚΟ',
    descr_en: 'GLYFADA - AGIA TRIADA - ST. ELLINIKO',
    routes: ['1919', '2317', '2958', '2959'],
  },
  '924': {
    ml: '275',
    line: '924',
    sdcs: ['54', '59', '60'],
    id: '164',
    descr: 'ΣΤ. ΑΓ. ΔΗΜΗΤΡΙΟΣ - Κ. ΗΛ/ΠΟΛΗ - ΑΡΓ/ΠΟΛΗ - ΤΕΡΨΙΘΕΑ',
    descr_en: 'ST. AGIOS DIMITRIOS - K. IL/POLI - ARG/POLI - TERPSITHEA',
    routes: ['1925', '1926', '2320', '2321'],
  },
  '926': {
    ml: '146',
    line: '926',
    sdcs: ['54', '59', '60'],
    id: '203',
    descr: 'ΚΑΡΕΑΣ - ΑΚΑΔΗΜΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KAREAS - AKADIMIA ',
    routes: ['1927'],
  },
  '927': {
    ml: '149',
    line: '927',
    sdcs: ['54', '59', '60'],
    id: '128',
    descr: 'ΓΛΥΦΑΔΑ - ΤΕΡΨΙΘΕΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'GLYFADA - TERPSITHEA',
    routes: ['1928'],
  },
  '929': {
    ml: '149',
    line: '929',
    sdcs: ['54', '59', '60'],
    id: '116',
    descr: 'ΓΛΥΦΑΔΑ - ΒΑΡΗ - ΚΙΤΣΙ',
    descr_en: 'GLYFADA - VARI - KITSI',
    routes: ['2159', '3032'],
  },
  '930': {
    ml: '275',
    line: '930',
    sdcs: ['54'],
    id: '165',
    descr: 'ΤΕΡΨΙΘΕΑ - ΑΡΓΥΡΟΥΠΟΛΗ - ΣΥΝΤΑΓΜΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'TERPSITHEA - ARGYROYPOLI - SYNTAGMA ',
    routes: ['1930', '2534'],
  },
  '931': {
    ml: '101',
    line: '931',
    sdcs: ['54', '59', '60'],
    id: 'Β2',
    descr: 'ΑΚΑΔΗΜΙΑ - ΑΓΙΟΣ ΚΟΣΜΑΣ (μέσω ΠΑΡΑΛΙΑΚΗΣ)',
    descr_en: 'AKADIMIA - AGIOS KOSMAS (VIA PARALIAKIS)',
    routes: ['1931', '1932'],
  },
  '932': {
    ml: '149',
    line: '932',
    sdcs: ['54', '59', '60'],
    id: '117',
    descr: 'ΓΛΥΦΑΔΑ - ΒΟΥΛΙΑΓΜΕΝΗ - ΒΑΡΗ',
    descr_en: 'GLYFADA - VOULIAGMENI - VARI',
    routes: ['1934', '3019'],
  },
  '933': {
    ml: '149',
    line: '933',
    sdcs: ['54', '59', '60'],
    id: '205',
    descr: 'ΓΛΥΦΑΔΑ - ΣΟΥΡΜΕΝΑ - ΤΕΡΨΙΘΕΑ ',
    descr_en: 'GLYFADA - SOYRMENA - TERPSITHEA ',
    routes: ['1935', '1936'],
  },
  '934': {
    ml: '160',
    line: '934',
    sdcs: ['54', '59', '60'],
    id: '815',
    descr: 'ΓΟΥΔΗ - ΤΑΥΡΟΣ',
    descr_en: 'GOYDI - TAYROS',
    routes: ['1937', '1938'],
  },
  '935': {
    ml: '160',
    line: '935',
    sdcs: ['54', '59', '60'],
    id: '622',
    descr: 'ΓΟΥΔΗ - ΑΝΩ ΓΑΛΑΤΣΙ',
    descr_en: 'GOYDI - ANO GALATSI',
    routes: ['1939', '1940', '2596'],
  },
  '936': {
    ml: '172',
    line: '936',
    sdcs: ['54', '59', '60'],
    id: '504',
    descr: 'ΘΡΑΚΟΜΑΚΕΔΟΝΕΣ - ΣΙΔ. ΣΤ. ΔΕΚΕΛΕΙΑΣ - ΚΗΦΙΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'THRAKOMAKEDONES - SID. ST. DEKELEIAS - KIFISIA ',
    routes: ['2925'],
  },
  '937': {
    ml: '106',
    line: '937',
    sdcs: ['54', '59', '60'],
    id: '140',
    descr: 'ΠΟΛΥΓΩΝΟ - ΓΛΥΦΑΔΑ',
    descr_en: 'POLIGONO - GLIFADA',
    routes: ['1942', '2723'],
  },
  '938': {
    ml: '107',
    line: '938',
    sdcs: ['54', '59', '60'],
    id: '040',
    descr: 'ΠΕΙΡΑΙΑΣ - ΣΥΝΤΑΓΜΑ',
    descr_en: 'PEIRAIAS - SINTAGMA',
    routes: ['2005', '2006'],
  },
  '939': {
    ml: '108',
    line: '939',
    sdcs: ['54', '59', '60'],
    id: '101',
    descr: 'ΑΛΙΜΟΣ - ΕΛΛΗΝΙΚΟ',
    descr_en: 'ALIMOS - ELLHNIKO',
    routes: ['1953', '1954'],
  },
  '940': {
    ml: '109',
    line: '940',
    sdcs: ['54', '59', '60'],
    id: '120',
    descr: 'ΓΛΥΦΑΔΑ - ΒΑΡΗ - ΠΡΟΑΣΤΙΑΚΟΣ ΣΤ. ΚΟΡΩΠΙΟΥ',
    descr_en: 'GLYFADA - VARI - PROAST. KOROPIOU',
    routes: ['2985', '3033'],
  },
  '941': {
    ml: '295',
    line: '941',
    sdcs: ['54', '59', '60'],
    id: '122',
    descr: 'ΣΤ. ΕΛΛΗΝΙΚΟ - ΣΑΡΩΝΙΔΑ',
    descr_en: 'ST. ELLINIKO - SARONIDA',
    routes: ['2008', '2957'],
  },
  '942': {
    ml: '111',
    line: '942',
    sdcs: ['64'],
    id: '142',
    descr: 'ΣΤ. ΑΡΓΥΡΟΥΠΟΛΗ - ΚΑΛΑΜΑΚΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. ARGYROYPOLI - KALAMAKI',
    routes: ['1971'],
  },
  '944': {
    ml: '113',
    line: '944',
    sdcs: ['64'],
    id: '162',
    descr: 'ΒΟΥΛΑ - ΠΑΝΟΡΑΜΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'VOYLA - PANORAMA',
    routes: ['2065'],
  },
  '945': {
    ml: '114',
    line: '945',
    sdcs: ['54', '59', '60'],
    id: '171',
    descr: 'ΣΤ. ΕΛΛΗΝΙΚΟ - ΒΑΡΚΙΖΑ',
    descr_en: 'ST. METRO ELLINIKO - VARKIZA',
    routes: ['2961', '3034'],
  },
  '946': {
    ml: '146',
    line: '946',
    sdcs: ['60'],
    id: '204',
    descr: 'ΣΤΡ. ΣΑΚΕΤΑ - ΑΚΑΔΗΜΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'STR. SAKETA - AKADIMIA ',
    routes: ['2089'],
  },
  '947': {
    ml: '116',
    line: '947',
    sdcs: ['54', '59', '60'],
    id: '209',
    descr: 'ΜΕΤΑΜΟΡΦΩΣΗ - ΣΥΝΤΑΓΜΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'METAMORFOSI - PLATEIA SYNTAGMATOS ',
    routes: ['1958'],
  },
  '948': {
    ml: '117',
    line: '948',
    sdcs: ['54', '59', '60'],
    id: '212',
    descr: 'ΣΤ. ΔΑΦΝΗ - ΥΜΗΤΤΟΣ - ΒΥΡΩΝΑΣ - ΚΑΡΕΑΣ',
    descr_en: 'ST. METRO DAFNI - VYRONAS - KAREAS',
    routes: ['1951', '1952'],
  },
  '949': {
    ml: '147',
    line: '949',
    sdcs: ['54', '59', '60'],
    id: '220',
    descr: 'ΑΝΩ ΙΛΙΣΙΑ - ΑΚΑΔΗΜΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANO ILISIA - AKADIMIA ',
    routes: ['2035'],
  },
  '950': {
    ml: '119',
    line: '950',
    sdcs: ['54', '59', '60'],
    id: '235',
    descr: 'ΖΩΓΡΑΦΟΥ - ΑΚΑΔΗΜΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ZOGRAFOY - AKADIMIA ',
    routes: ['1950'],
  },
  '951': {
    ml: '120',
    line: '951',
    sdcs: ['54', '58'],
    id: '242',
    descr: 'ΣΤ. ΚΑΤΕΧΑΚΗ - ΠΟΛΥΤΕΧΝΕΙΟΥΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. KATECHAKI - POLITECHNIOUPOLI',
    routes: ['2120', '2267'],
  },
  '952': {
    ml: '121',
    line: '952',
    sdcs: ['54'],
    id: '250',
    descr: 'ΠΑΝΕΠΙΣΤΗΜΙΟΥΠΟΛΗ - ΣΤ. ΕΥΑΓΓΕΛΙΣΜΟΥ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PANEPISTIMIOUPOLI - ST. EVAGGELISMOU',
    routes: ['2108'],
  },
  '953': {
    ml: '148',
    line: '953',
    sdcs: ['54', '59', '60'],
    id: '304',
    descr: 'ΣΤ. ΝΟΜΙΣΜΑΤΟΚΟΠΕΙΟ - ΑΡΤΕΜΙΣ (ΒΡΑΥΡΩΝΑ)',
    descr_en: 'ST. NOMISMATOKOPEIO - ARTEMIS (VRAYRONA)',
    routes: ['2055', '2056'],
  },
  '954': {
    ml: '148',
    line: '954',
    sdcs: ['54', '59', '60'],
    id: '305',
    descr: 'ΣΤ. ΝΟΜΙΣΜΑΤΟΚΟΠΕΙΟ - ΑΡΤΕΜΙΣ (ΑΓ. ΝΙΚΟΛΑΟΣ)',
    descr_en: 'ST. NOMISMATOKOPEIO - ARTEMIS (AG. NIKOLAOS)',
    routes: ['2036', '2037'],
  },
  '955': {
    ml: '166',
    line: '955',
    sdcs: ['54', '59', '60'],
    id: '308',
    descr: 'ΣΤ. ΝΟΜΙΣΜΑΤΟΚΟΠΕΙΟ - ΠΑΙΑΝΙΑ - ΚΟΡΩΠΙ',
    descr_en: 'ST. NOMISMATOKOPEIO - PAIANIA - KOROPI',
    routes: ['2059', '2060'],
  },
  '956': {
    ml: '125',
    line: '956',
    sdcs: ['54', '59', '60'],
    id: '406',
    descr: 'ΣΤ. ΝΟΜΙΣΜΑΤΟΚΟΠΕΙΟ - ΑΓ. ΠΑΡΑΣΚΕΥΗ - ΣΤ. ΠΛΑΚΕΝΤΙΑΣ',
    descr_en: 'ST. NOMISMATOKOPEIO - AG. PARASKEYI - ST. PLAKENTIAS',
    routes: ['1955', '1956'],
  },
  '957': {
    ml: '126',
    line: '957',
    sdcs: ['54', '59', '60'],
    id: '407',
    descr: 'ΣΤ. ΝΟΜΙΣΜΑΤΟΚΟΠΕΙΟ - ΝΕΑ ΖΩΗ - ΣΤ. ΔΟΥΚ. ΠΛΑΚΕΝΤΙΑΣ',
    descr_en: 'ST. NOMISMATOKOPEIO - NEA ZOI - ST. DOYK. PLAKENTIAS',
    routes: ['2082', '2083'],
  },
  '959': {
    ml: '135',
    line: '959',
    sdcs: ['54', '59', '60'],
    id: '550',
    descr: 'Π. ΦΑΛΗΡΟ - ΚΗΦΙΣΙΑ',
    descr_en: 'P. FALIRO - KIFISIA',
    routes: ['1965', '2951'],
  },
  '960': {
    ml: '134',
    line: '960',
    sdcs: ['54', '59', '60'],
    id: '608',
    descr: 'ΓΑΛΑΤΣΙ - ΑΚΑΔΗΜΙΑ - ΝΕΚΡ. ΖΩΓΡΑΦΟΥ',
    descr_en: 'GALATSI - AKADIMIA - NEKR. ZOGRAFOY',
    routes: ['2003', '2004'],
  },
  '961': {
    ml: '133',
    line: '961',
    sdcs: ['54', '59', '60'],
    id: '910',
    descr: 'ΣΤ. ΣΥΓΓΡΟΥ ΦΙΞ - ΤΖΙΤΖΙΦΙΕΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. SYGGROY FIX - TZITZIFIES',
    routes: ['2551'],
  },
  '962': {
    ml: '131',
    line: '962',
    sdcs: ['54', '59', '60'],
    id: 'Α1',
    descr: 'ΠΕΙΡΑΙΑΣ - ΒΟΥΛΑ',
    descr_en: 'PEIRAIAS - VOYLA',
    routes: ['2045', '2721'],
  },
  '963': {
    ml: '132',
    line: '963',
    sdcs: ['54', '59', '60'],
    id: 'Α2',
    descr: 'ΑΚΑΔΗΜΙΑ - ΒΟΥΛΑ (ΜΕΣΩ Λ. ΑΜΦΙΘΕΑΣ)',
    descr_en: 'AKADIMIA - VOYLA (VIA L. AMFITHEAS)',
    routes: ['2047', '2722'],
  },
  '964': {
    ml: '130',
    line: '964',
    sdcs: ['54', '59', '60'],
    id: 'Α3',
    descr: 'ΑΚΑΔΗΜΙΑ - ΓΛΥΦΑΔΑ (ΜΕΣΩ Λ. ΒΟΥΛΙΑΓΜΕΝΗΣ)',
    descr_en: 'AKADIMIA - GLYFADA (VIA VOYLIAGMENIS)',
    routes: ['2049', '2050', '2279'],
  },
  '965': {
    ml: '129',
    line: '965',
    sdcs: ['54', '59', '60'],
    id: 'Α7',
    descr: 'ΣΤΟΥΡΝΑΡΗ - ΚΗΦΙΣΙΑ (ΠΛΑΤΕΙΑ ΠΛΑΤΑΝΟΥ)',
    descr_en: 'STOYRNARI - KIFISIA (PLATEIA PLATANOU)',
    routes: ['2085', '2086'],
  },
  '966': {
    ml: '128',
    line: '966',
    sdcs: ['81'],
    id: 'Χ97',
    descr: 'ΣΤ.ΕΛΛΗΝΙΚΟ - ΑΕΡΟΛΙΜΕΝΑΣ ΑΘΗΝΩΝ (EXPRESS) ',
    descr_en: 'ST. METRO ELLINIKO - AEROL. ATHINON (EXPRESS) ',
    routes: ['2088', '2962'],
  },
  '967': {
    ml: '221',
    line: '967',
    sdcs: ['54', '59', '60'],
    id: '421',
    descr: 'ΑΓ. ΑΝΑΡΓΥΡΟΙ - ΑΓ. ΠΑΡΑΣΚΕΥΗ',
    descr_en: 'AGIOI ANARGYROI - AGIA PARASKEVI',
    routes: ['2113', '2114'],
  },
  '968': {
    ml: '230',
    line: '968',
    sdcs: ['54', '59', '60'],
    id: '703',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΑΝΑΡΓΥΡΟΙ - ΑΓ. ΕΛΕΥΘΕΡΙΟΣ (ΜΕΣΩ ΘΗΒΩΝ)',
    descr_en: 'PEIRAIAS - AG. ANARGYROI - AG. ELEYTHERIOS (VIA THIVON)',
    routes: ['1982', '2881'],
  },
  '969': {
    ml: '236',
    line: '969',
    sdcs: ['54', '59', '60'],
    id: '843',
    descr: 'ΠΕΙΡΑΙΑΣ - ΠΕΡΑΜΑ',
    descr_en: 'PEIRAIAS - PERAMA',
    routes: ['2074', '2075'],
  },
  '970': {
    ml: '159',
    line: '970',
    sdcs: ['54', '59', '60'],
    id: 'Β18',
    descr: 'ΟΜΟΝΟΙΑ - ΠΕΡΑΜΑ (ΜΕΣΩ ΠΕΤΡΟΥ ΡΑΛΛΗ)',
    descr_en: 'OMONOIA - PERAMA (VIA PETROY RALLI)',
    routes: ['2134', '2135'],
  },
  '972': {
    ml: '159',
    line: '972',
    sdcs: ['54', '59', '60'],
    id: 'Γ18',
    descr: 'ΟΜΟΝΟΙΑ - ΠΕΡΑΜΑ (ΜΕΣΩ ΓΡ. ΛΑΜΠΡΑΚΗ)',
    descr_en: 'OMONOIA - PERAMA (VIA GRIGORIOU LAMPRAKI)',
    routes: ['2132', '2133'],
  },
  '974': {
    ml: '137',
    line: '974',
    sdcs: ['54', '59'],
    id: '027',
    descr: 'ΙΠΠΟΚΡΑΤΟΥΣ - ΟΡΦΕΩΣ',
    descr_en: 'IPPOKRATOUS - ORFEOS',
    routes: ['1975', '1976'],
  },
  '975': {
    ml: '207',
    line: '975',
    sdcs: ['54', '59', '60'],
    id: '230',
    descr: 'ΑΚΡΟΠΟΛΗ - ΖΩΓΡΑΦΟΥ',
    descr_en: 'AKROPOLI - ZOGRAFOY',
    routes: ['1973', '1974'],
  },
  '976': {
    ml: '189',
    line: '976',
    sdcs: ['54', '59', '60'],
    id: '447',
    descr: 'ΧΑΛΑΝΔΡΙ - ΑΝΩ ΒΡΙΛΗΣΣΙΑ',
    descr_en: 'CHALANDRI - ANO BRILHSSIA',
    routes: ['1986', '2838', '2920'],
  },
  '977': {
    ml: '138',
    line: '977',
    sdcs: ['54', '59', '60'],
    id: '501',
    descr: 'ΠΕΥΚΗ - ΜΑΡΟΥΣΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEYKI - MAROYSI ',
    routes: ['2229'],
  },
  '978': {
    ml: '138',
    line: '978',
    sdcs: ['54', '59', '60'],
    id: '527',
    descr: 'ΠΕΥΚΗ - ΝΕΡΑΤΖΙΩΤΙΣΣΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEYKI - NERATZIOTISSA ',
    routes: ['2230'],
  },
  '979': {
    ml: '224',
    line: '979',
    sdcs: ['64'],
    id: '541',
    descr: 'ΜΕΤΑΜΟΡΦΩΣΗ - ΣΤ. ΑΜΑΡΟΥΣΙΟΥ (ΚΥΚΛΙΚΗ)',
    descr_en: 'METAMORFOSI - ST. AMAROUSIOU ',
    routes: ['2117'],
  },
  '980': {
    ml: '225',
    line: '980',
    sdcs: ['54', '68'],
    id: '543',
    descr: 'ΣΤΑΘΜΟΣ ΚΗΦΙΣΙΑΣ - ΝΟΣ. ΑΓ. ΑΝΑΡΓΥΡΟΙ',
    descr_en: 'ST. KIFISIA - NOS. AG. ANARGYROI',
    routes: ['2210', '2211'],
  },
  '981': {
    ml: '274',
    line: '981',
    sdcs: ['54', '59', '60'],
    id: '602',
    descr: 'Ν. ΙΩΝΙΑ - ΚΑΛΟΓΡΕΖΑ - ΣΤΑΘ. ΠΑΝΟΡΜΟΥ (ΚΥΚΛΙΚΗ)',
    descr_en: 'N. IONIA - KALOGREZA - ST. PANORMOY ',
    routes: ['2143'],
  },
  '982': {
    ml: '274',
    line: '982',
    sdcs: ['54', '59', '60'],
    id: '642',
    descr: 'ΗΡΑΚΛΕΙΟ - ΑΓ. ΝΕΚΤΑΡΙΟΣ - ΠΕΥΚΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'IRAKLEIO - AG. NEKTARIOS - PEYKI ',
    routes: ['2182'],
  },
  '983': {
    ml: '250',
    line: '983',
    sdcs: ['64'],
    id: '604',
    descr: 'ΝΟΣ. ΑΓ. ΑΝΑΡΓΥΡΟΙ - ΣΤ. ΜΕΤΑΜΟΡΦΩΣΗΣ - ΝΕΑ ΙΩΝΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'NOS. AG. ANARGYROI - ST. METAMORFOSIS - NEA IONIA',
    routes: ['2358'],
  },
  '984': {
    ml: '274',
    line: '984',
    sdcs: ['54', '59', '60'],
    id: '640',
    descr: 'ΤΟΠΙΚΗ ΗΡΑΚΛΕΙΟΥ (ΚΥΚΛΙΚΗ)',
    descr_en: 'TOPIKI IRAKLEIOY ',
    routes: ['2337', '2362'],
  },
  '985': {
    ml: '190',
    line: '985',
    sdcs: ['54', '59', '60'],
    id: '610',
    descr: 'ΦΙΛΟΘΕΗ - ΑΜΠΕΛΟΚΗΠΟΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'FILOTHEI - AMPELOKIPOI ',
    routes: ['2144'],
  },
  '986': {
    ml: '140',
    line: '986',
    sdcs: ['54'],
    id: '705',
    descr: 'ΚΑΜΑΤΕΡΟ - ΙΛΙΟΝ - ΠΛ. ΑΓ. ΑΝΑΡΓΥΡΩΝ - ΣΤ. ΑΝΘΟΥΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KAMATERO - ILION - PL. AG. ANARGYRON - ST. ANTHOYPOLI',
    routes: ['2678'],
  },
  '987': {
    ml: '140',
    line: '987',
    sdcs: ['54', '59', '60'],
    id: '701',
    descr: 'ΚΑΜΑΤΕΡΟ - ΙΛΙΟΝ - ΣΤ. ΑΝΘΟΥΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KAMATERO - ILION ST. ANTHOYPOLI ',
    routes: ['2676'],
  },
  '989': {
    ml: '192',
    line: '989',
    sdcs: ['54', '59', '60'],
    id: '730',
    descr: 'ΛΟΦΟΣ ΑΞΙΩΜΑΤΙΚΩΝ - ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ - ΖΩΟΔ. ΠΗΓΗ',
    descr_en: 'LOFOS AKSIOMATIKON - ST. AGIOS ANTONIOS - ZOODOCHOS PIGI',
    routes: ['2119', '2878', '3020'],
  },
  '990': {
    ml: '233',
    line: '990',
    sdcs: ['54', '59', '60'],
    id: '747',
    descr: 'ΠΕΤΡΟΥΠΟΛΗ - ΠΑΝΟΡΑΜΑ - ΣΤ. ΑΝΘΟΥΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PETROYPOLI - PANORAMA - ST. ANTHOYPOLI ',
    routes: ['2078'],
  },
  '991': {
    ml: '294',
    line: '991',
    sdcs: ['54', '59', '60'],
    id: '876',
    descr: 'ΕΛΕΥΣΙΝΑ - ΣΤ. ΑΓ. ΜΑΡΙΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ELEYSINA - ST. AG. MARINA ',
    routes: ['2880'],
  },
  '993': {
    ml: '191',
    line: '993',
    sdcs: ['81'],
    id: 'Χ14',
    descr: 'ΣΥΝΤΑΓΜΑ - ΚΗΦΙΣΙΑ (EXPRESS)',
    descr_en: 'SYNTAGMA - KIFISIA (EXPRESS)',
    routes: ['2184', '2185'],
  },
  '994': {
    ml: '247',
    line: '994',
    sdcs: ['54'],
    id: 'Ε14',
    descr: 'ΣΥΝΤΑΓΜΑ - Ο.Α.Κ.Α. - ΥΠ. ΠΑΙΔΕΙΑΣ (EXPRESS)',
    descr_en: 'SYNTAGMA - O.A.K.A. - YP. PAIDEIAS (EXPRESS)',
    routes: ['2062', '2234'],
  },
  '995': {
    ml: '144',
    line: '995',
    sdcs: ['54', '59', '60'],
    id: '805',
    descr: 'ΑΣΠΡΟΠΥΡΓΟΣ - ΝΕΟΚΤΙΣΤΑ',
    descr_en: 'ASPROPYRGOS - NEOKTISTA',
    routes: ['2197', '2198'],
  },
  '996': {
    ml: '144',
    line: '996',
    sdcs: ['54', '59', '60'],
    id: '862',
    descr: 'ΠΑΡΑΛΙΑ - ΑΝΩ ΕΛΕΥΣΙΝΑ',
    descr_en: 'PARALIA - ANO ELEYSINA',
    routes: ['2146', '2150'],
  },
  '997': {
    ml: '145',
    line: '997',
    sdcs: ['54', '59', '60'],
    id: '523',
    descr: 'ΣΤΑΘΜΟΣ ΚΗΦΙΣΙΑΣ - ΑΔΑΜΕΣ Β (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. KIFISIA - ADAMES B ',
    routes: ['2740', '2994'],
  },
  '998': {
    ml: '145',
    line: '998',
    sdcs: ['54', '59', '60'],
    id: '522',
    descr: 'ΣΤΑΘΜΟΣ ΚΗΦΙΣΙΑΣ - ΑΔΑΜΕΣ Α (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. KIFISIA - ADAMES A ',
    routes: ['2739', '2993'],
  },
  '999': {
    ml: '145',
    line: '999',
    sdcs: ['54', '59', '60'],
    id: '530',
    descr: 'ΣΤΑΘΜΟΣ ΚΗΦΙΣΙΑΣ - ΜΕΛΙΣΣΙΑ - Ν. ΠΕΝΤΕΛΗ',
    descr_en: 'ST. KIFISIA - MELISSIA - N. PENTELI',
    routes: ['2129', '2130', '2325'],
  },
  '1000': {
    ml: '145',
    line: '1000',
    sdcs: ['54', '59', '60'],
    id: '526',
    descr: 'ΣΤΑΘΜΟΣ ΚΗΦΙΣΙΑΣ - ΚΕΦΑΛΑΡΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. KIFISIA- KEFALARI ',
    routes: ['2213'],
  },
  '1001': {
    ml: '145',
    line: '1001',
    sdcs: ['54', '59', '60'],
    id: '524',
    descr: 'ΣΤΑΘΜΟΣ ΚΗΦΙΣΙΑΣ - ΠΟΛΙΤΕΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. KIFISIA - POLITEIA ',
    routes: ['2289'],
  },
  '1003': {
    ml: '200',
    line: '1003',
    sdcs: ['54', '59', '60'],
    id: '109',
    descr: 'ΣΤ. ΑΓ. ΔΗΜΗΤΡΙΟΣ - ΑΝΩ ΚΑΛΑΜΑΚΙ',
    descr_en: 'ST. AG. DHMHTRIOS - ANO KALAMAKI',
    routes: ['2031', '2032'],
  },
  '1004': {
    ml: '147',
    line: '1004',
    sdcs: ['54', '59', '60'],
    id: '221',
    descr: 'ΠΑΝΕΠΙΣΤΗΜΙΟΥΠΟΛΗ - ΑΚΑΔΗΜΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PANEPISTIMIOYPOLI - AKADIMIA ',
    routes: ['2154'],
  },
  '1005': {
    ml: '148',
    line: '1005',
    sdcs: ['54', '59', '60'],
    id: '316',
    descr: 'ΣΤ. ΝΟΜΙΣΜΑΤΟΚΟΠΕΙΟ - ΑΡΤΕΜΙΣ',
    descr_en: 'ST. NOMISMATOKOPEIO - ARTEMIS',
    routes: ['2291', '2292'],
  },
  '1006': {
    ml: '188',
    line: '1006',
    sdcs: ['54', '59', '60'],
    id: '309',
    descr: 'ΚΟΡΩΠΙ - ΣΤ. ΠΡΟΑΣΤΙΑΚΟΥ - ΚΟΡΩΠΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KOROPI - ST. PROASTIAKOY - KOROPI ',
    routes: ['1970'],
  },
  '1007': {
    ml: '289',
    line: '1007',
    sdcs: ['54', '59', '60'],
    id: '653',
    descr: 'ΨΥΧΙΚΟ - ΠΑΝΟΡΜΟΥ Β (ΚΥΚΛΙΚΗ)',
    descr_en: 'PSYCHIKO - PANORMOY Β ',
    routes: ['2157'],
  },
  '1008': {
    ml: '149',
    line: '1008',
    sdcs: ['54', '59', '60'],
    id: '115',
    descr: 'ΓΛΥΦΑΔΑ - ΒΟΥΛΙΑΓΜΕΝΗ - ΚΙΤΣΙ',
    descr_en: 'GLYFADA - VOULIAGMENI - KITSI',
    routes: ['2186', '2187'],
  },
  '1009': {
    ml: '64',
    line: '1009',
    sdcs: ['54'],
    id: '836',
    descr: 'ΠΛ. ΚΟΥΜΟΥΝΔΟΥΡΟΥ - ΠΑΛΑΣΚΑ',
    descr_en: 'PL. KOYMOYNDOYROY - PALASKA',
    routes: ['2216', '2299'],
  },
  '1010': {
    ml: '255',
    line: '1010',
    sdcs: ['54', '59', '60'],
    id: '818',
    descr: 'ΛΙΜ. ΠΕΡΑΜΑΤΟΣ - ΑΝΩ ΠΕΡΑΜΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'LIM. PERAMATOS - ANO PERAMA ',
    routes: ['2000'],
  },
  '1011': {
    ml: '156',
    line: '1011',
    sdcs: ['54', '59', '60'],
    id: '838',
    descr: 'ΟΜΟΝΟΙΑ - ΡΕΝΤΗΣ - ΠΑΛ. ΚΟΚΚΙΝΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'OMONOIA - RENTIS - PAL. KOKKINIA ',
    routes: ['2161'],
  },
  '1013': {
    ml: '237',
    line: '1013',
    sdcs: ['54', '59', '60'],
    id: '859',
    descr: 'ΠΕΙΡΑΙΑΣ-ΔΕΞΑΜΕΝΕΣ-ΝΕΟΣ ΜΩΛΟΣ ΔΡΑΠΕΤΣΩΝΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEIRAIAS - DEXAMENES - N.MOLOS DRAPETSONAS',
    routes: ['2645'],
  },
  '1014': {
    ml: '238',
    line: '1014',
    sdcs: ['54', '59', '60'],
    id: '860',
    descr: 'Π. ΦΑΛΗΡΟ - ΓΕΝ. ΚΡΑΤΙΚΟ ΝΙΚΑΙΑΣ - ΣΧΙΣΤΟ ΚΕΡΑΤΣΙΝΙΟΥ',
    descr_en: 'P. FALIRO - GEN. KRATIKO NIKAIAS - SCHISTO KERATSINIOY',
    routes: ['2140', '2141'],
  },
  '1015': {
    ml: '239',
    line: '1015',
    sdcs: ['54', '59', '60'],
    id: '904',
    descr: 'ΠΛ. ΚΑΡΑΪΣΚΑΚΗ - ΣΤ. ΠΕΙΡΑΙΑ - ΦΡΕΑΤΤΥΔΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PL. KARAΪSKAKI - ST. PEIRAIA - FREATΤYDA ',
    routes: ['2162'],
  },
  '1017': {
    ml: '241',
    line: '1017',
    sdcs: ['54'],
    id: '911',
    descr: 'ΣΤΑΘ. ΚΑΛΛΙΘΕΑΣ - ΙΔΡ. ΤΥΦΛΩΝ (ΚΥΚΛΙΚΗ)',
    descr_en: 'STATH. KALLITHEAS - IDR. TYFLON ',
    routes: ['2274'],
  },
  '1018': {
    ml: '242',
    line: '1018',
    sdcs: ['54', '59', '60'],
    id: '915',
    descr: 'ΛΟΦΟΣ ΒΩΚΟΥ - ΠΡ. ΗΛΙΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'LOFOS VOKOY - PR. ILIAS ',
    routes: ['2044'],
  },
  '1020': {
    ml: '279',
    line: '1020',
    sdcs: ['54', '59', '60'],
    id: 'Α10',
    descr: 'ΣΤΑΘΜΟΣ ΛΑΡΙΣΗΣ - ΑΧΑΡΝΑΙ',
    descr_en: 'STATHMOS LARISIS - ACHARNAI',
    routes: ['2076', '2077'],
  },
  '1021': {
    ml: '161',
    line: '1021',
    sdcs: ['54', '59', '60'],
    id: 'Β10',
    descr: 'ΣΤΑΘΜΟΣ ΛΑΡΙΣΗΣ - ΑΧΑΡΝΑΙ (ΜΕΣΩ ΑΡΙΣΤΟΤΕΛΟΥΣ)',
    descr_en: 'STATHMOS LARISIS - ACHARNAI (VIA ARISTOTELOYS)',
    routes: ['2136', '2137'],
  },
  '1023': {
    ml: '246',
    line: '1023',
    sdcs: ['54', '59', '60'],
    id: 'Β9',
    descr: 'ΧΑΛΚΟΚΟΝΔΥΛΗ - ΜΕΤΑΜΟΡΦΩΣΗ - Ν. ΚΗΦΙΣΙΑ',
    descr_en: 'CHALKOKONDYLI - METAMORFOSI - N. KIFISIA',
    routes: ['2042', '2043'],
  },
  '1024': {
    ml: '245',
    line: '1024',
    sdcs: ['54', '59', '60'],
    id: 'Β5',
    descr: 'ΣΤ. ΛΑΡΙΣΗΣ - Λ. ΑΛΕΞΑΝΔΡΑΣ - ΑΓ. ΠΑΡΑΣΚΕΥΗ',
    descr_en: 'ST. LARISIS - L. ALEXANDRAS - AG. PARASKEYI',
    routes: ['2167', '2168'],
  },
  '1025': {
    ml: '249',
    line: '1025',
    sdcs: ['81'],
    id: 'Χ95',
    descr: 'ΣΥΝΤΑΓΜΑ - ΑΕΡΟΛ. ΑΘΗΝΩΝ (EXPRESS)',
    descr_en: 'SYNTAGMA - AEROL. ATHINON (EXPRESS)',
    routes: ['2051', '2052'],
  },
  '1026': {
    ml: '162',
    line: '1026',
    sdcs: ['54', '59', '60'],
    id: '106',
    descr: 'ΑΓ. ΒΑΡΒΑΡΑ - ΣΤ. ΣΥΓΓΡΟΥ ΦΙΞ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. BARBARA - ST. SYGGROY FIX',
    routes: ['2017'],
  },
  '1027': {
    ml: '162',
    line: '1027',
    sdcs: ['54', '59', '60'],
    id: '126',
    descr: 'ΠΑΛ. ΦΑΛΗΡΟ - ΣΤ. ΣΥΓΓΡΟΥ ΦΙΞ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PAL. FALIRO - ST. SYNGROU FIX ',
    routes: ['2039', '2319'],
  },
  '1028': {
    ml: '201',
    line: '1028',
    sdcs: ['54', '59', '60'],
    id: '112',
    descr: 'ΑΓ. ΔΗΜΗΤΡΙΟΣ - ΣΤ. ΔΑΦΝΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. DIMITRIOS - STATΗ. DAFNIS',
    routes: ['2022'],
  },
  '1030': {
    ml: '163',
    line: '1030',
    sdcs: ['54', '59', '60'],
    id: '137',
    descr: 'ΑΝΩ Ν. ΣΜΥΡΝΗ Α - ΣΤ. ΣΥΓΓΡΟΥ ΦΙΞ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANO N. SMYRNI A - ST. SYGGROY FIX ',
    routes: ['2026'],
  },
  '1031': {
    ml: '163',
    line: '1031',
    sdcs: ['54', '59', '60'],
    id: '136',
    descr: 'ΑΝΩ Ν. ΣΜΥΡΝΗ Β - ΣΤ. ΣΥΓΓΡΟΥ ΦΙΞ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANO N. SMYRNI V - ST. SYGGROY FIX ',
    routes: ['2016'],
  },
  '1032': {
    ml: '204',
    line: '1032',
    sdcs: ['54', '59', '60'],
    id: '141',
    descr: 'ΣΤ. ΔΑΦΝΗ - ΚΑΛΑΜΑΚΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. DAFNIS - KALAMAKI',
    routes: ['2552'],
  },
  '1033': {
    ml: '234',
    line: '1033',
    sdcs: ['54', '59', '60'],
    id: '217',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΔΗΜΗΤΡΙΟΣ - ΣΤ. ΔΑΦΝΗ',
    descr_en: 'PEIRAIAS - AG. DIMITRIOS - ST. DAFNI',
    routes: ['1979', '1980'],
  },
  '1034': {
    ml: '164',
    line: '1034',
    sdcs: ['54', '59', '60'],
    id: '229',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΔΗΜΗΤΡΙΟΣ - ΣΤ. ΔΑΦΝΗ',
    descr_en: 'PEIRAIAS - AG. DIMITRIOS - ST. DAFNI',
    routes: ['1981', '2769'],
  },
  '1035': {
    ml: '287',
    line: '1035',
    sdcs: ['54', '59', '60'],
    id: '218',
    descr: 'ΠΕΙΡΑΙΑΣ - ΣΤ. ΔΑΦΝΗ',
    descr_en: 'PEIRAIAS - ST. DAFNI',
    routes: ['2034', '2810', '2899'],
  },
  '1036': {
    ml: '165',
    line: '1036',
    sdcs: ['54', '59', '60'],
    id: '219',
    descr: 'ΣΤ. ΚΑΛΛΙΘΕΑ - ΑΓ. ΔΗΜΗΤΡΙΟΣ',
    descr_en: 'ST. KALLITHEA - AG. DIMITRIOS',
    routes: ['2215', '2809'],
  },
  '1037': {
    ml: '165',
    line: '1037',
    sdcs: ['54'],
    id: '816',
    descr: 'ΤΑΥΡΟΣ - ΑΓ. ΔΗΜΗΤΡΙΟΣ',
    descr_en: 'TAYROS - AG. DIMITRIOS',
    routes: ['2228', '2811'],
  },
  '1039': {
    ml: '209',
    line: '1039',
    sdcs: ['54', '59', '60'],
    id: '302',
    descr: 'ΣΤ. ΔΟΥΚ. ΠΛΑΚΕΝΤΙΑΣ - ΓΕΡΑΚΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. DOYK. PLAKENTIAS - GERAKAS ',
    routes: ['2115'],
  },
  '1041': {
    ml: '211',
    line: '1041',
    sdcs: ['54', '59', '60'],
    id: '307',
    descr: 'ΣΤ. Δ.ΠΛΑΚΕΝΤΙΑΣ - ΓΛ. ΝΕΡΑ - ΣΤ.ΠΡΟΑΣΤ. ΚΟΡΩΠΙ',
    descr_en: 'ST. DOYK. PLAKENTIAS - GL. NERA - PROAST. KOROPIOY',
    routes: ['2513', '2518'],
  },
  '1042': {
    ml: '210',
    line: '1042',
    sdcs: ['64', '60'],
    id: '306',
    descr: 'ΣΤ.ΔΟΥΚ.ΠΛΑΚΕΝΤΙΑΣ-ΔΗΜ.ΓΕΡΑΚΑ-ΜΠΑΛΑΝΑ ΓΕΡΑΚΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.DOYK.PLAKENTIAS-DIM.GERAKA-BALANA GERAKA',
    routes: ['2427'],
  },
  '1043': {
    ml: '166',
    line: '1043',
    sdcs: ['54', '59', '60'],
    id: '330',
    descr: 'ΚΟΡΩΠΙ - ΑΓ. ΜΑΡΙΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'KOROPI - AGIA MARINA ',
    routes: ['2984'],
  },
  '1046': {
    ml: '300',
    line: '1046',
    sdcs: ['54', '59', '60'],
    id: '322',
    descr: 'ΑΓ. ΝΙΚΟΛΑΟΣ - ΒΡΑΥΡΩΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. NIKOLAOS - VRAYRONA ',
    routes: ['2067'],
  },
  '1047': {
    ml: '213',
    line: '1047',
    sdcs: ['54', '59', '60'],
    id: '319',
    descr: 'ΣΤ. ΔΟΥΚ. ΠΛΑΚΕΝΤΙΑΣ - ΠΑΛΛΗΝΗ - ΕΚΠΤ. ΧΩΡΙΟ ΣΠΑΤΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. DOYK. PLAKENTIAS - PALLINI - EKPT. CHORIO SPATA',
    routes: ['2066'],
  },
  '1050': {
    ml: '215',
    line: '1050',
    sdcs: ['54', '59', '60'],
    id: '402',
    descr: 'ΣΤ. ΚΑΤΕΧΑΚΗ - ΠΟΛΥΔΡΟΣΟ',
    descr_en: 'ST. KATECHAKI - POLYDROSO',
    routes: ['2971', '2972'],
  },
  '1051': {
    ml: '167',
    line: '1051',
    sdcs: ['54', '59', '60'],
    id: '413',
    descr: 'ΠΑΠΑΓΟΥ - ΣΤ. ΚΑΤΕΧΑΚΗ - ΣΤ. ΕΘΝ. ΑΜΥΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PAPAGOY - ST. KATECHAKI - ST. ETHN. AMYNAS ',
    routes: ['2171'],
  },
  '1052': {
    ml: '167',
    line: '1052',
    sdcs: ['54', '59', '60'],
    id: '409',
    descr: 'ΠΑΠΑΓΟΥ - ΣΤ. ΕΘΝ. ΑΜΥΝΑ - ΣΤ. ΚΑΤΕΧΑΚΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PAPAGOY - ST. ETHN. AMYNAS - ST. KATECHAKI ',
    routes: ['2112', '2394'],
  },
  '1053': {
    ml: '218',
    line: '1053',
    sdcs: ['54', '59', '60'],
    id: '411',
    descr: 'ΣΤ. ΔΟΥΚ. ΠΛΑΚΕΝΤΙΑΣ - ΣΙΣΜΑΝΟΓΛΕΙΟ - ΧΑΛΑΝΔΡΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. DOUKISSIS PLAKENTIAS - SISMANOGLIO - CHALANDRI',
    routes: ['2326', '2835'],
  },
  '1055': {
    ml: '223',
    line: '1055',
    sdcs: ['54', '59', '60'],
    id: '446',
    descr: 'ΣΤ. ΑΜΑΡΟΥΣΙΟΥ - ΝΟΣΟΚ. ΠΕΝΤΕΛΗΣ (ΜΕΣΩ ΠΙΚΠΑ)',
    descr_en: 'ST. AMAROYSIOY - NOSOK. PENTELIS (VIA PIKPA)',
    routes: ['2169', '2170'],
  },
  '1056': {
    ml: '222',
    line: '1056',
    sdcs: ['54', '59', '60'],
    id: '444',
    descr: 'ΣΤ. ΕΙΡΗΝΗ - ΓΑΛΑΤΣΙ - ΣΤ. ΑΝΩ ΠΑΤΗΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. EIRINI - GALATSI - ST. ANO PATISIA ',
    routes: ['2106'],
  },
  '1057': {
    ml: '289',
    line: '1057',
    sdcs: ['54', '59', '60'],
    id: '651',
    descr: 'ΨΥΧΙΚΟ - ΠΑΝΟΡΜΟΥ Α (ΚΥΚΛΙΚΗ)',
    descr_en: 'PSYCHIKO - PANORMOY A ',
    routes: ['2260'],
  },
  '1058': {
    ml: '243',
    line: '1058',
    sdcs: ['54', '59', '60'],
    id: 'Α5',
    descr: 'ΑΚΑΔΗΜΙΑ - ΑΓ. ΠΑΡΑΣΚΕΥΗ - ΑΝΘΟΥΣΑ',
    descr_en: 'AKADIMIA - AG. PARASKEYI - ANTHOYSA',
    routes: ['2138', '2952'],
  },
  '1059': {
    ml: '168',
    line: '1059',
    sdcs: ['54', '59', '60'],
    id: '450',
    descr: 'ΣΤ. ΧΑΛΑΝΔΡΙ - ΧΑΛΑΝΔΡΙ - Ν. ΠΕΝΤΕΛΗ',
    descr_en: 'ST. CHALANDRI - CHALANDRI - N. PENTELI',
    routes: ['2072', '2073'],
  },
  '1060': {
    ml: '168',
    line: '1060',
    sdcs: ['54', '59', '60'],
    id: '451',
    descr: 'ΣΤ. ΧΑΛΑΝΔΡΙ - Ν. ΠΕΝΤΕΛΗ - ΧΑΛΑΝΔΡΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. CHALANDRI - NEA PENTELI - CHALANDRI',
    routes: ['2840'],
  },
  '1061': {
    ml: '168',
    line: '1061',
    sdcs: ['54', '59', '60'],
    id: '460',
    descr: 'ΣΤ. ΧΑΛΑΝΔΡΙ - ΧΑΛΑΝΔΡΙ - Π. ΠΕΝΤΕΛΗ',
    descr_en: 'ST. CHALANDRI - CHALANDRI - PALAIA PENTELI',
    routes: ['2127', '2128'],
  },
  '1062': {
    ml: '168',
    line: '1062',
    sdcs: ['54', '59', '60'],
    id: '461',
    descr: 'ΣΤ. ΧΑΛΑΝΔΡΙ - Π. ΠΕΝΤΕΛΗ - ΧΑΛΑΝΔΡΙ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. CHALANDRI - P. PENTELI - CHALANDRI ',
    routes: ['2173', '2797'],
  },
  '1063': {
    ml: '226',
    line: '1063',
    sdcs: ['54', '59', '60'],
    id: '619',
    descr: 'ΜΕΤΑΜΟΡΦΩΣΗ - Ν. ΦΙΛΑΔΕΛΦΕΙΑ - ΣΤ. ΑΝΩ ΠΑΤΗΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'METAMORFOSI - N. FILADELFEIA - ST. ANO PATISIA ',
    routes: ['2219'],
  },
  '1064': {
    ml: '271',
    line: '1064',
    sdcs: ['54', '59', '60'],
    id: '724',
    descr: ' ΑΧΑΡΝΑΙ (ΜΕΣΟΝΥΧΙ) - Ν. ΙΩΝΙΑ (ΚΥΚΛΙΚΗ) ',
    descr_en: 'ACHARNAI (MESONYCHI) - N. IONIA ',
    routes: ['2447'],
  },
  '1065': {
    ml: '193',
    line: '1065',
    sdcs: ['54', '59', '60'],
    id: '740',
    descr: 'ΟΛΥΜΠ. ΧΩΡΙΟ - ΣΚΑ - ΑΧΑΡΝΑΙ - ΑΓ. ΑΝΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'OLYMP. CHORIO - SKA - ACHARNAI - AG. ANNA',
    routes: ['2796'],
  },
  '1066': {
    ml: '194',
    line: '1066',
    sdcs: ['54', '59', '60'],
    id: '823',
    descr: 'ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ - ΑΓ. ΒΑΣΙΛΕΙΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. AG. ANTONIOS - AG. VASILEIOS ',
    routes: ['2218'],
  },
  '1067': {
    ml: '195',
    line: '1067',
    sdcs: ['54', '59', '60'],
    id: '822',
    descr: 'ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ - ΝΕΑ ΖΩΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.AG.ANTONIOS - NEA ZOI ',
    routes: ['2142'],
  },
  '1068': {
    ml: '173',
    line: '1068',
    sdcs: ['70', '75', '76'],
    id: '3',
    descr: 'Ν. ΦΙΛΑΔΕΛΦΕΙΑ - ΑΝΩ ΠΑΤΗΣΙΑ - ΝΕΟ ΨΥΧΙΚΟ',
    descr_en: 'N. FILADELFEIA - ANO PATISIA - NEO PSYCHIKO',
    routes: ['1989', '1990'],
  },
  '1069': {
    ml: '182',
    line: '1069',
    sdcs: ['70', '75', '76'],
    id: '1',
    descr: 'ΠΛ. ΑΤΤΙΚΗΣ - ΚΑΛΛΙΘΕΑ - ΜΟΣΧΑΤΟ',
    descr_en: 'PLATEIA ATTIKIS - TZITZIFIES - MOSCHATO',
    routes: ['2018', '2019'],
  },
  '1070': {
    ml: '169',
    line: '1070',
    sdcs: ['70', '75', '76'],
    id: '2',
    descr: 'ΑΝΩ ΚΥΨΕΛΗ - ΠΑΓΚΡΑΤΙ - ΚΑΙΣΑΡΙΑΝΗ',
    descr_en: 'ANO KIPSELI - PAGKRATI - KAISARIANH',
    routes: ['2020', '2021'],
  },
  '1071': {
    ml: '169',
    line: '1071',
    sdcs: ['70', '75', '76'],
    id: '4',
    descr: 'ΑΝΩ ΚΥΨΕΛΗ - ΑΓ. ΑΡΤΕΜΙΟΣ - ΣΤ. ΑΓ. ΙΩΑΝΝΗΣ',
    descr_en: 'ANO KYPSELI - AG. ARTEMIOS - ST. AG. IOANNIS',
    routes: ['2024', '2025'],
  },
  '1072': {
    ml: '174',
    line: '1072',
    sdcs: ['70', '75', '76'],
    id: '5',
    descr: 'ΛΑΜΠΡΙΝΗ - ΠΛ. ΣΥΝΤΑΓΜΑΤΟΣ - ΤΖΙΤΖΙΦΙΕΣ',
    descr_en: 'LAMPRINI - PL. SYNTAGMATOS - TZITZIFIES',
    routes: ['1991', '1992'],
  },
  '1073': {
    ml: '175',
    line: '1073',
    sdcs: ['70', '75', '76'],
    id: '6',
    descr: 'ΙΠΠΟΚΡΑΤΟΥΣ - ΚΟΣΜΑΣ ΑΙΤΩΛΟΣ - Ν. ΦΙΛΑΔΕΛΦΕΙΑ',
    descr_en: 'IPPOKRATOUS - KOSMAS AITOLOS - N. FILADELFEIA',
    routes: ['1987', '1988'],
  },
  '1074': {
    ml: '183',
    line: '1074',
    sdcs: ['70', '75', '76'],
    id: '15',
    descr: 'ΠΕΤΡΑΛΩΝΑ - ΔΙΚΑΣΤΗΡΙΑ - ΕΛ. ΒΕΝΙΖΕΛΟΥ',
    descr_en: 'PETRALONA - DIKASTIRIA - EL. VENIZELOY',
    routes: ['2012', '2013'],
  },
  '1076': {
    ml: '184',
    line: '1076',
    sdcs: ['70', '75', '76'],
    id: '16',
    descr: 'ΑΓ. ΙΩΑΝΝΗΣ ΡΕΝΤΗΣ - ΠΕΙΡΑΙΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. IOANNIS RENTIS - PEIRAIAS ',
    routes: ['2079'],
  },
  '1077': {
    ml: '176',
    line: '1077',
    sdcs: ['70', '75', '76'],
    id: '10',
    descr: 'ΤΖΙΤΖΙΦΙΕΣ - ΧΑΛΑΝΔΡΙ',
    descr_en: 'TZITZIFIES - XALANDRI',
    routes: ['1993', '1994'],
  },
  '1078': {
    ml: '178',
    line: '1078',
    sdcs: ['70', '75', '76'],
    id: '14',
    descr: 'ΛΑΜΠΡΙΝΗ - Λ. ΑΛΕΞΑΝΔΡΑΣ - ΓΗΡΟΚΟΜΕΙΟ',
    descr_en: 'LAMPRINI - L. ALEXANDRAS - GIROKOMEIO',
    routes: ['1997', '1998'],
  },
  '1079': {
    ml: '177',
    line: '1079',
    sdcs: ['70', '75', '76'],
    id: '11',
    descr: 'ΑΝΩ ΠΑΤΗΣΙΑ - Ν. ΠΑΓΚΡΑΤΙ - Ν. ΕΛΒΕΤΙΑ',
    descr_en: 'ANO PATISIA - N. PAGKRATI - N. ELVETIA',
    routes: ['1995', '1996', '2275'],
  },
  '1080': {
    ml: '179',
    line: '1080',
    sdcs: ['70', '75', '76'],
    id: '12',
    descr: 'ΖΑΠΠΕΙΟ - ΠΕΡΙΣΤΕΡΙ (ΑΓ. ΙΕΡΟΘΕΟΣ)',
    descr_en: 'ZAPPEIO - PERISTERI (AG. IEROTHEOS)',
    routes: ['2009', '2010'],
  },
  '1081': {
    ml: '180',
    line: '1081',
    sdcs: ['70', '75', '76'],
    id: '24',
    descr: 'ΠΕΤΡΟΥΠΟΛΗ - ΙΛΙΟΝ - ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PETROYPOLI - ILION - ST. AG. ANTONIOS ',
    routes: ['1962'],
  },
  '1082': {
    ml: '187',
    line: '1082',
    sdcs: ['70', '75', '76'],
    id: '21',
    descr: 'ΝΙΚΑΙΑ - Π. ΡΑΛΛΗ - ΟΜΟΝΟΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'NIKAIA - P. RALLI - OMONOIA ',
    routes: ['2080'],
  },
  '1083': {
    ml: '186',
    line: '1083',
    sdcs: ['70', '75', '76'],
    id: '20',
    descr: 'Ν. ΦΑΛΗΡΟ - ΚΑΣΤΕΛΛΑ - ΔΡΑΠΕΤΣΩΝΑ',
    descr_en: 'N. FALIRO - KASTELLA - DRAPETSONA',
    routes: ['1960', '1961'],
  },
  '1084': {
    ml: '170',
    line: '1084',
    sdcs: ['70', '75', '76'],
    id: '19',
    descr: 'ΜΟΥΣΕΙΟ - ΣΤ. ΧΑΛΑΝΔΡΙΟΥ (ΣΙΔΕΡΑ)',
    descr_en: 'MOYSEIO - ST. CHALANDRIOY (SIDERA)',
    routes: ['2029', '2030'],
  },
  '1085': {
    ml: '170',
    line: '1085',
    sdcs: ['70', '75', '76'],
    id: '18',
    descr: 'ΜΟΥΣΕΙΟ - ΧΑΛΑΝΔΡΙ (ΜΕΣΩ ΕΘΝ. ΑΝΤΙΣΤΑΣΕΩΣ)',
    descr_en: 'MOYSEIO - CHALANDRI (VIA ETHN. ANTISTASEOS)',
    routes: ['2027', '2028'],
  },
  '1086': {
    ml: '185',
    line: '1086',
    sdcs: ['70', '75', '76'],
    id: '17',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΓΕΩΡΓΙΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PEIRAIAS - AG. GEORGIOS ',
    routes: ['1959'],
  },
  '1113': {
    ml: '207',
    line: '1113',
    sdcs: ['54'],
    id: '032',
    descr: 'ΓΟΥΔΗ - ΜΑΡΑΣΛΕΙΟΣ (ΣΧΟΛΙΚΗ)',
    descr_en: 'GOUDI-MARASLIOS',
    routes: ['2780', '2781'],
  },
  '1114': {
    ml: '157',
    line: '1114',
    sdcs: ['54'],
    id: '801',
    descr: 'ΠΕΙΡΑΙΑΣ - ΠΑΛΑΣΚΑ',
    descr_en: 'PEIRAIAS - PALASKA',
    routes: ['2301'],
  },
  '1124': {
    ml: '262',
    line: '1124',
    sdcs: ['81'],
    id: '123',
    descr: 'ΣΑΡΩΝΙΔΑ-ΑΝΑΒΥΣΣΟΣ-ΠΑΛΑΙΑ ΦΩΚΑΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'SARONIDA-ANAVISSOS-P.FOKAIA',
    routes: ['2493'],
  },
  '1126': {
    ml: '267',
    line: '1126',
    sdcs: ['54', '68'],
    id: '713',
    descr: 'ΑΝΩ ΛΙΟΣΙΑ (ΠΑΝΟΡΑΜΑ) - ΣΤ. ΠΡΟΑΣΤΙΑΚΟΥ ΑΝΩ ΛΙΟΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANO LIOSIA (PANORAMA)-ST. PROASTIAKOY ANO LIOSIA',
    routes: ['2404', '3050'],
  },
  '1129': {
    ml: '288',
    line: '1129',
    sdcs: ['54', '68'],
    id: '324',
    descr: 'ΑΝΘΟΥΣΑ-ΠΑΛΛΗΝΗ-ΚΑΝΤΖΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ANTHOUSA-PALLINI-KANTZA',
    routes: ['2588'],
  },
  '1131': {
    ml: '141',
    line: '1131',
    sdcs: ['54', '59', '60'],
    id: '709',
    descr: 'ΤΟΠΙΚΟ ΔΡΟΣΟΥΠΟΛΗΣ (ΚΥΚΛΙΚΟ)',
    descr_en: 'DROSOYPOLI ',
    routes: ['2973'],
  },
  '1133': {
    ml: '271',
    line: '1133',
    sdcs: ['54', '59', '60'],
    id: '725',
    descr: 'ΑΧΑΡΝΑΙ (ΜΕΣΟΝΥΧΙ) - ΘΡΑΚΟΜΑΚΕΔΟΝΕΣ (ΠΛ.ΑΡΙΣΤΟΤΕΛΟΥΣ)  (ΚΥΚΛΙΚΗ)',
    descr_en: 'ACHARNAI(MESONYCHI)-THRAKOMAKEDONES (PL.ARISTOTELOUS)',
    routes: ['2942'],
  },
  '1134': {
    ml: '271',
    line: '1134',
    sdcs: ['54', '59', '60'],
    id: '726',
    descr: 'ΑΧΑΡΝΑΙ (ΜΕΣΟΝΥΧΙ) - ΘΡΑΚΟΜΑΚΕΔΟΝΕΣ (BOYNO)   (ΚΥΚΛΙΚΗ)',
    descr_en: 'ACHARNAI(MESONYCHI)-THRAKOMAKEDONES (MOUNT PARNITHA)',
    routes: ['2654'],
  },
  '1137': {
    ml: '155',
    line: '1137',
    sdcs: ['54', '59', '60'],
    id: '832',
    descr: 'ΕΥΓΕΝΕΙΑ-ΧΑΡΑΥΓΗ-ΠΕΙΡΑΙΑΣ Α (ΚΥΚΛΙΚΗ)',
    descr_en: 'EYGENEIA-CHARAYGI-PEIRAIAS A',
    routes: ['2458', '2647'],
  },
  '1144': {
    ml: '279',
    line: '1144',
    sdcs: ['54', '59', '60'],
    id: '755',
    descr: 'ΑΧΑΡΝΑΙ-ΚΟΚΚΙΝΟΣ ΜΥΛΟΣ-ΣΤ. ΚΑΤΩ ΠΑΤΗΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AXARNAI-KOKKINOS MYLOS-ST.KATO PATHSIA',
    routes: ['2468'],
  },
  '1145': {
    ml: '283',
    line: '1145',
    sdcs: ['54', '59', '60'],
    id: '735',
    descr: 'ΖΕΦΥΡΙ-ΣΤ.ΚΑΤΩ ΠΑΤΗΣΙΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ZEFYRI-ST.KATO PATHSIA',
    routes: ['3039'],
  },
  '1147': {
    ml: '282',
    line: '1147',
    sdcs: ['54', '59', '60'],
    id: '727',
    descr: 'ΑΓ. ΚΩΝΣΤΑΝΤΙΝΟΣ-ΜΕΣΟΝΥΧΙ-ΑΓ.ΠΑΡΑΣΚΕΥΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG.KONSTANTINOS-MESONYXI-AG.PARASKEYH',
    routes: ['2477'],
  },
  '1149': {
    ml: '281',
    line: '1149',
    sdcs: ['54', '59', '60'],
    id: '154',
    descr: 'ΣΤ.ΕΛΛΗΝΙΚΟ - Α.ΓΛΥΦΑΔΑ- ΠΑΝ/ΜΑ ΒΟΥΛΑΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.ELLINIKO - ANO GLYFADA - PAN/MA VOYLAS',
    routes: ['2960'],
  },
  '1150': {
    ml: '247',
    line: '1150',
    sdcs: ['54'],
    id: 'Ε14',
    descr:
      'ΣΥΝΤΑΓΜΑ - Ο.Α.Κ.Α. - ΥΠ. ΠΑΙΔΕΙΑΣ (EXPRESS) (ΕΩΣ ΟΑΚΑ ΑΠΟΓΕΥΜΑΤΙΝΑ )',
    descr_en: 'SYNTAGMA - O.A.K.A. - YP. PAIDEIAS (EXPRESS) (TO OAKA)',
    routes: ['2482', '2483'],
  },
  '1151': {
    ml: '9',
    line: '1151',
    sdcs: ['54', '59', '60'],
    id: '021',
    descr: 'ΠΛΑΤΕΙΑ ΚΑΝΙΓΓΟΣ - ΓΚΥΖH(ΚΥΚΛΙΚΗ)',
    descr_en: 'PLATEIA KANIGKOS - GKIZI',
    routes: ['2484'],
  },
  '1153': {
    ml: '73',
    line: '1153',
    sdcs: ['81'],
    id: 'Χ96',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΕΡ/ΝΑΣ ΑΘΗΝΩΝ (EXPRESS)-ΝΥΧΤΕΡΙΝΗ',
    descr_en: 'PEIRAIAS - AEROLIMENAS ATHINON (EXPRESS) (LATE AND NIGHT HOURS)',
    routes: ['3030', '3031'],
  },
  '1154': {
    ml: '128',
    line: '1154',
    sdcs: ['81'],
    id: 'Χ97',
    descr: 'ΣΤ.ΕΛΛΗΝΙΚΟ-ΑΕΡ/ΝΑΣ ΑΘΗΝΩΝ (EXPRESS)-ΝΥΧΤΕΡΙΝΗ',
    descr_en:
      'ST.METRO ELLINIKO - AEROL.ATHINON (EXPRESS) (NIGHTLINE-FROM ST.DAFNI)',
    routes: ['2491', '2492'],
  },
  '1158': {
    ml: '181',
    line: '1158',
    sdcs: ['70', '75', '76'],
    id: '25',
    descr: 'ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ-ΙΛΙΟΝ-ΚΑΜΑΤΕΡΟ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.AG.ANTONIOS-ILION-KAMATERO',
    routes: ['2883'],
  },
  '1161': {
    ml: '212',
    line: '1161',
    sdcs: ['54', '59', '60'],
    id: '314',
    descr: 'ΣΤ.ΔΟΥΚ.ΠΛΑΚΕΝΤΙΑΣ - ΠΑΛΛΗΝΗ - ΡΑΦΗΝΑ',
    descr_en: 'ST.DOUK.PLAKENTIAS - PALLINI - RAFINA',
    routes: ['2724', '2725'],
  },
  '1162': {
    ml: '263',
    line: '1162',
    sdcs: ['54', '59', '60'],
    id: '301',
    descr: 'ΣΤ. ΔΟΥΚ. ΠΛΑΚΕΝΤΙΑΣ - ΑΝΘΟΥΣΑ - ΠΕΝΤΕΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. DOUK. PLAKENTIAS - ANTHOUSA - PENTELI',
    routes: ['2529'],
  },
  '1164': {
    ml: '220',
    line: '1164',
    sdcs: ['54', '59', '60'],
    id: '420',
    descr: 'ΠΕΙΡΑΙΑΣ - ΑΓ. ΑΝΑΡΓΥΡΟΙ  (ΜΕΣΩ ΚΗΦΙΣΟΥ) ',
    descr_en: 'PEIRAIAS - AG. ANARGYROI (VIA KIFISOY) ',
    routes: ['2541', '2542'],
  },
  '1165': {
    ml: '263',
    line: '1165',
    sdcs: ['54'],
    id: '301Β',
    descr: 'ΣΤ. ΔΟΥΚ. ΠΛΑΚΕΝΤΙΑΣ-ΑΝΘΟΥΣΑ-ΠΕΝΤΕΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. DOUK. PLAKENTIAS - ANTHOUSA - PENTELI',
    routes: ['2544', '2545'],
  },
  '1172': {
    ml: '95',
    line: '1172',
    sdcs: ['54', '59', '60'],
    id: '206',
    descr: 'ΑΓ.ΜΑΡΙΝΑ - ΣΤ. ΔΑΦΝΗΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AGIA MARINA - ST. DAFNIS',
    routes: ['2556'],
  },
  '1173': {
    ml: '94',
    line: '1173',
    sdcs: ['54', '59', '60'],
    id: '237',
    descr: 'ΑΝΩ ΗΛΙΟΥΠΟΛΗ - ΣΤ. ΔΑΦΝΗΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.DAFNIS - ANO ILIOUPLI',
    routes: ['2557'],
  },
  '1175': {
    ml: '287',
    line: '1175',
    sdcs: ['54', '59', '60'],
    id: '131',
    descr: 'ΑΜΑΞ. ΑΓ. ΔΗΜΗΤΡΙΟΥ - ΣΤ. ΔΑΦΝΗΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AMAX. AG. DIMITRIOU - ST. DAFNIS',
    routes: ['2560'],
  },
  '1176': {
    ml: '287',
    line: '1176',
    sdcs: ['54', '59', '60'],
    id: '201',
    descr: 'ΣΤ. ΑΓ. ΔΗΜΗΤΡΙΟΣ - ΑΡΓΥΡΟΥΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. AG. DIMITRIOS - ARGYROYPOLI',
    routes: ['2561'],
  },
  '1178': {
    ml: '146',
    line: '1178',
    sdcs: ['54', '59'],
    id: '204Β',
    descr: 'ΕΚΕΜΣ - ΑΚΑΔΗΜΙΑ(ΚΥΚΛΙΚΗ)',
    descr_en: 'EKEMS -AKADIMIA',
    routes: ['2564'],
  },
  '1180': {
    ml: '8',
    line: '1180',
    sdcs: ['113', '58', '59', '60'],
    id: '051',
    descr: 'ΟΜΟΝΟΙΑ - ΣΤ. ΥΠΕΡΑΣΤ. ΛΕΩΦ. ΚΗΦΙΣΟΥ (ΚΥΚΛΙΚΗ)',
    descr_en: 'OMONOIA - ST. YPERAST. LEOF. KIFISOU',
    routes: ['2567'],
  },
  '1183': {
    ml: '38',
    line: '1183',
    sdcs: ['54', '59', '60'],
    id: '891',
    descr: 'ΑΓ. ΒΑΡΒΑΡΑ - ΑΙΓΑΛΕΩ - ΠΕΡΙΣΤΕΡΙ - ΣΤΑΘ. ΑΤΤΙΚΗΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. BARBARA - AIGALEO - PERISTERI - STATH. ATTIKIS',
    routes: ['2857'],
  },
  '1184': {
    ml: '26',
    line: '1184',
    sdcs: ['54', '59', '60'],
    id: 'Α8',
    descr: 'ΠΟΛΥΤΕΧΝΕΙΟ - Ν. ΙΩΝΙΑ - ΜΑΡΟΥΣΙ (ΕΝΑΛΛΑΚΤΙΚΗ ΛΟΓΩ ΑΓΩΝΑ-ΟΑΚΑ)',
    descr_en: 'POLYTEXNEIO - N. IONIA - MAROUSI (DUE TO ATHLETIC EVENTS)',
    routes: ['2598', '2599'],
  },
  '1186': {
    ml: '247',
    line: '1186',
    sdcs: ['54'],
    id: 'Ε14',
    descr: 'ΣΥΝΤΑΓΜΑ - Ο.Α.ΚΑ. - ΥΠ. ΠΑΙΔΕΙΑ (ΕΝΑΛΛΑΚΤΙΚΗ ΛΟΓΩ ΑΓΩΝΑ-ΟΑΚΑ)',
    descr_en:
      'SYNTAGMA - O.A.K.A. - YP. PAIDEIAS (EXPRESS) (DUE TO ATHLETIC EVENTS)',
    routes: ['2603', '2604'],
  },
  '1187': {
    ml: '87',
    line: '1187',
    sdcs: ['54', '59', '60'],
    id: '509',
    descr: 'ΖΗΡΙΝΕΙΟ - ΑΓ. ΣΤΕΦΑΝΟΣ - ΚΡΥΟΝΕΡΙ',
    descr_en: 'ZIRINEIO - AG. STEFANOS - KRYONERI',
    routes: ['2611', '2612'],
  },
  '1188': {
    ml: '222',
    line: '1188',
    sdcs: ['54', '59', '60'],
    id: '444',
    descr:
      'ΣΤ. ΕΙΡΗΝΗ - ΓΑΛΑΤΣΙ - ΣΤ. ΑΝΩ ΠΑΤΗΣΙΑ (ΕΝΑΛΛΑΚΤΙΚΗ ΛΟΓΩ ΑΓΩΝΑ-ΟΑΚΑ)',
    descr_en: 'ST. EIRINI - GALATSI - ST. ANO PATISIA (DUE TO ATHLETIC EVENTS)',
    routes: ['2613'],
  },
  '1190': {
    ml: '40',
    line: '1190',
    sdcs: ['54', '59', '60'],
    id: '410',
    descr: 'Ο.Α.Κ.Α. - ΑΝΩ ΜΕΛΙΣΣΙΑ (ΕΝΑΛΛΑΚΤΙΚΗ ΛΟΓΩ ΑΓΩΝΑ-ΟΑΚΑ)',
    descr_en: 'O.A.K.A. - ANO MELISSIA (DUE TO ATHLETIC EVENTS)',
    routes: ['2615'],
  },
  '1195': {
    ml: '138',
    line: '1195',
    sdcs: ['54', '59', '60'],
    id: '501',
    descr: 'ΠΕΥΚΗ - ΜΑΡΟΥΣΙ (ΚΥΚΛΙΚΗ) ΕΝΑΛΛΑΚΤΙΚΗ ΛΟΓΩ ΛΑΪΚΗΣ 06:00-16:30',
    descr_en: "PEYKI MAROYSI (DURING THE FARMER'S MARKET 06:00-16:30)",
    routes: ['2639'],
  },
  '1196': {
    ml: '138',
    line: '1196',
    sdcs: ['54', '59', '60'],
    id: '527',
    descr:
      'ΠΕΥΚΗ - ΝΕΡΑΤΖΙΩΤΙΣΣΑ (ΚΥΚΛΙΚΗ) ΕΝΑΛΛΑΚΤΙΚΗ ΛΟΓΩ ΛΑΪΚΗΣ 06:00-16:30',
    descr_en: "PEYKI- NERATZIOTISSA (DURING THE FARMER'S MARKET 06:00-16:30)",
    routes: ['2641'],
  },
  '1197': {
    ml: '155',
    line: '1197',
    sdcs: ['54', '59', '60'],
    id: '833',
    descr: 'ΕΥΓΕΝΕΙΑ-ΧΑΡΑΥΓΗ-ΠΕΙΡΑΙΑΣ Β (ΚΥΚΛΙΚΗ)',
    descr_en: 'EYGENEIA-CHARAYGI-PEIRAIAS B',
    routes: ['2644', '2649'],
  },
  '1198': {
    ml: '291',
    line: '1198',
    sdcs: ['55', '69'],
    id: '715',
    descr: 'ΖΩΦΡΙΑ (ΜΑΚ) - ΑΝΩ ΛΙΟΣΙΑ (3ο ΛΥΚΕΙΟ)',
    descr_en: 'ZOFRIA (MAK) -ANO LIOSIA (3rd LYKEIO)',
    routes: ['2653'],
  },
  '1201': {
    ml: '291',
    line: '1201',
    sdcs: ['55', '69'],
    id: '716',
    descr: 'ΑΝΩ ΛΙΟΣΙΑ (3ο ΓΥΜΝΑΣΙΟ)-ΖΩΦΡΙΑ (ΜΑΚ)',
    descr_en: 'ANO LIOSIA (3rd GYMNASIO)-ZOFRIA (MAK)',
    routes: ['2666'],
  },
  '1202': {
    ml: '267',
    line: '1202',
    sdcs: ['54', '68'],
    id: '712',
    descr: 'ΑΧΑΡΝΑΙ-ΕΡΓ. ΠΟΛΥΚΑΤΟΙΚΙΕΣ-Α. ΛΙΟΣΙΑ (ΠΑΝΟΡΑΜΑ) ',
    descr_en: 'AXARNAI-A.LIOSIA(PANORAMA)',
    routes: ['2682', '2864', '3049'],
  },
  '1203': {
    ml: '230',
    line: '1203',
    sdcs: ['54'],
    id: 'Ε90',
    descr: 'ΠΕΙΡΑΙΑΣ - ΠΑΝΕΠΙΣΤΗΜΙΟΥΠΟΛΗ (ΣΧΟΛΙΚΗ EXPRESS)',
    descr_en: 'PEIRAIAS - PANEPISTIMIOYPOLI (SCHOLIKI EXPRESS)',
    routes: ['2693', '2694'],
  },
  '1205': {
    ml: '87',
    line: '1205',
    sdcs: ['54'],
    id: '509',
    descr: 'ΖΗΡΙΝΕΙΟ - ΑΓ. ΣΤΕΦΑΝΟΣ - ΚΡΥΟΝΕΡΙ (ΜΕΧΡΙ ΚΥΝΟΥΡΙΑ)',
    descr_en: 'ZIRINEIO - AG. STEFANOS - KRYONERI (VIA KYNOURIA)',
    routes: ['2704', '2705'],
  },
  '1209': {
    ml: '195',
    line: '1209',
    sdcs: ['54'],
    id: '822',
    descr: 'ΣΤ.ΑΓ.ΑΝΤΩΝΙΟΣ -ΝΕΑ ΖΩΗ (ΑΠΟ ΑΛΣΟΣ ΝΕΑ ΖΩΗ ΕΩΣ ΟΜΟΝΟΙΑ)',
    descr_en: 'ST.AG.ANTONIOS - NEA ZOI (N.ZOI TO OMONOIA)',
    routes: ['2710'],
  },
  '1210': {
    ml: '195',
    line: '1210',
    sdcs: ['54', '59', '60'],
    id: '822',
    descr: 'ΣΤ.ΑΓ.ΑΝΤΩΝΙΟΣ -ΝΕΑ ΖΩΗ (ΑΠΟ ΑΛΣΟΣ ΝΕΑ ΖΩΗ ΕΩΣ ΣΤ. ΑΓ.ΑΝΤΩΝΙΟΣ)',
    descr_en: 'ST.AG.ANTONIOS - NEA ZOI (N.ZOI TO ST.AG.ANTONIOS)',
    routes: ['2711'],
  },
  '1211': {
    ml: '195',
    line: '1211',
    sdcs: ['54', '59', '60'],
    id: '822',
    descr: 'ΣΤ.ΑΓ.ΑΝΤΩΝΙΟΣ -ΝΕΑ ΖΩΗ (ΑΠΟ ΣΤ. ΑΓ.ΑΝΤΩΝΙΟΣ ΕΩΣ ΑΛΣΟΣ ΝΕΑ ΖΩΗ)',
    descr_en: 'ST.AG.ANTONIOS - NEA ZOI (ST.AG.ANTONIOS TO N.ZOI)',
    routes: ['2712'],
  },
  '1212': {
    ml: '87',
    line: '1212',
    sdcs: ['54'],
    id: '509',
    descr: 'ΖΗΡΙΝΕΙΟ - ΑΓ. ΣΤΕΦΑΝΟΣ (ΠΕΥΚΟΦΥΤΟ) - ΚΡΥΟΝΕΡΙ (ΜΕΧΡΙ ΚΥΝΟΥΡΙΑ)',
    descr_en: 'ZIRINEIO - AG. STEFANOS (PEFKOFYTO) - KRYONERI (VIA KYNOURIA)',
    routes: ['2713', '2714'],
  },
  '1213': {
    ml: '212',
    line: '1213',
    sdcs: ['54', '59', '60'],
    id: '314',
    descr: 'ΣΤ.ΔΟΥΚ.ΠΛΑΚΕΝΤΙΑΣ - ΠΑΛΛΗΝΗ - ΡΑΦΗΝΑ (ΜΕΣΩ ΠΑΝΟΡΑΜΑΤΟΣ)',
    descr_en: 'ST.DOUK.PLAKENTIAS - PALLINI - RAFINA (VIA PANORAMA)',
    routes: ['2715', '2716'],
  },
  '1219': {
    ml: '3',
    line: '1219',
    sdcs: ['54', '59'],
    id: '036',
    descr: 'ΣΤ. ΚΑΤΕΧΑΚΗ -ΣΤ. ΠΑΝΟΡΜΟΥ-ΓΑΛΑΤΣΙ-ΚΥΨΕΛΗ (ΜΕΧΡΙ ΠΛΑΤΕΙΑ ΚΥΨΕΛΗΣ)',
    descr_en: 'KATECHAKI - KYPSELI',
    routes: ['2895'],
  },
  '1220': {
    ml: '3',
    line: '1220',
    sdcs: ['54', '59'],
    id: '036',
    descr: 'ΣΤ. ΚΑΤΕΧΑΚΗ -ΣΤ. ΠΑΝΟΡΜΟΥ-ΓΑΛΑΤΣΙ-ΚΥΨΕΛΗ (ΑΠΟ ΠΛΑΤΕΙΑ ΚΥΨΕΛΗΣ)',
    descr_en: 'KATECHAKI - KYPSELI',
    routes: ['2751', '2756'],
  },
  '1221': {
    ml: '162',
    line: '1221',
    sdcs: ['54'],
    id: '106',
    descr: 'ΑΓ. ΒΑΡΒΑΡΑ - ΣΤ. ΣΥΓΓΡΟΥ ΦΙΞ (ΕΩΣ ΑΚΑΔΗΜΙΑ)',
    descr_en: 'AG. BARBARA - ST. SYGGROY FIX (TO AKADIMIA)',
    routes: ['2759'],
  },
  '1223': {
    ml: '299',
    line: '1223',
    sdcs: ['81'],
    id: '326',
    descr: 'ΣΤ. ΚΑΝΤΖΑ - ΣΠΑΤΑ - ΑΡΤΕΜΙΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ARTEMIS - KANTZA',
    routes: ['2770'],
  },
  '1225': {
    ml: '112',
    line: '1225',
    sdcs: ['64', '60'],
    id: '155',
    descr: 'ΣΤ. ΑΓ. ΔΗΜΗΤΡΙΟΣ - ΕΛΛΗΝΙΚΟ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.AG.DIMITRIOS - ELLINIKO',
    routes: ['2896'],
  },
  '1234': {
    ml: '294',
    line: '1234',
    sdcs: ['54', '59', '60'],
    id: '865',
    descr: 'ΑΜΑΞΟΣΤΑΣΙΟ ΒΟΤΑΝΙΚΟΥ - ΜΑΝΔΡΑ',
    descr_en: 'DEPOT VOTANIKOU  - MANDRA',
    routes: ['2823'],
  },
  '1236': {
    ml: '219',
    line: '1236',
    sdcs: ['54', '59', '60'],
    id: '416',
    descr: 'ΣΤ.ΕΘΝ.ΑΜΥΝΑΣ-ΧΟΛΑΡΓΟΣ (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. ETHN.AMYNAS-CHOLARGOS',
    routes: ['2847'],
  },
  '1237': {
    ml: '127',
    line: '1237',
    sdcs: ['54', '59', '60'],
    id: '418',
    descr: 'ΣΤ. ΕΘΝΙΚΗΣ ΑΜΥΝΑΣ-ΣΧΟΛ. ΧΟΛΑΡΓΟΥ(ΚΥΚΛΙΚΗ)',
    descr_en: 'ST.ETHNIKIS AMYNAS-SCHOL. CHOLARGOY',
    routes: ['2848', '2849'],
  },
  '1238': {
    ml: '216',
    line: '1238',
    sdcs: ['64'],
    id: '404',
    descr: 'ΣΤ. ΧΑΛΑΝΔΡΙΟΥ-ΣΤ. ΕΘΝ. ΑΜΥΝΑΣ(ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. CHALANDRIOY-ST. ETN. AMYNAS',
    routes: ['2853', '2854'],
  },
  '1241': {
    ml: '296',
    line: '1241',
    sdcs: ['81'],
    id: '310',
    descr: 'ΜΑΡΑΘΩΝΑΣ-ΝΕΑ ΜΑΚΡΗ-ΡΑΦΗΝΑ-ΜΑΤΙ-ΜΑΡΑΘΩΝΑΣ',
    descr_en: 'MARATHONAS-NEA MAKRI-RAFINA-MATI-MARATHONAS',
    routes: ['2903'],
  },
  '1244': {
    ml: '36',
    line: '1244',
    sdcs: ['54', '59', '60'],
    id: '700',
    descr: 'ΠΕΤΡΟΥΠΟΛΗ (ΑΓ. ΤΡΙΑΔΑ)-ΣΤ. ΑΝΘΟΥΠΟΛΗ (ΚΥΚΛΙΚΗ)',
    descr_en: 'PETROUPOLI (AG. TRIADA)-ST. ANTHOUPOLI',
    routes: ['2866'],
  },
  '1247': {
    ml: '297',
    line: '1247',
    sdcs: ['54', '59', '60'],
    id: '706',
    descr: 'ΣΤ. ΑΓ. ΑΝΤΩΝΙΟΣ-ΙΛΙΟΝ (ΜΕΣΩ Λ. ΑΝ. ΠΑΠΑΝΔΡΕΟΥ) (ΚΥΚΛΙΚΗ)',
    descr_en: 'ST. AG. ANTONIOS-ILION (VIA L. ANDREA PAPANDREOU)',
    routes: ['2872'],
  },
  '1248': {
    ml: '298',
    line: '1248',
    sdcs: ['81'],
    id: '734',
    descr: 'ΑΧΑΡΝΑΙ - ΝΟΣ. ΑΓ. ΑΝΑΡΓΥΡΟΙ',
    descr_en: 'ACHARNAI - NOS. AG. ANARGYROI',
    routes: ['2884', '2885'],
  },
  '1252': {
    ml: '300',
    line: '1252',
    sdcs: ['54', '59', '60'],
    id: '323',
    descr: 'ΑΓ. ΝΙΚΟΛΑΟΣ - ΒΡΑΥΡΩΝΑ (ΚΥΚΛΙΚΗ)',
    descr_en: 'AG. NIKOLAOS - VRAYRONA ',
    routes: ['2906'],
  },
  '1259': {
    ml: '141',
    line: '1259',
    sdcs: ['54', '59', '60'],
    id: '749',
    descr: 'ΟΙΚΙΣΜΟΣ ΓΕΝΝΗΜΑΤΑ-ΣΤ. ΠΡΟΑΣΤΙΑΚΟΥ',
    descr_en: 'OIKISMOS GENNIMATA-ST. PROASTIAKOU',
    routes: ['2965', '2976'],
  },
  '1260': {
    ml: '87',
    line: '1260',
    sdcs: ['54', '59', '60'],
    id: '535Α',
    descr: 'ΖΗΡΙΝΕΙΟ - ΕΥΞΕΙΝΟΣ ΠΟΝΤΟΣ - ΦΡΑΓΜΑ - ΚΑΛΕΤΖΙ',
    descr_en: 'ZIRINEIO - EYXEINOS PONTOS - KALETZI',
    routes: ['2986'],
  },
  '1261': {
    ml: '240',
    line: '1261',
    sdcs: ['54', '59', '60'],
    id: '909',
    descr: 'ΑΓ. ΒΑΣΙΛΕΙΟΣ - ΑΓ. ΣΟΦΙΑ - ΚΡΑΤ. ΝΙΚΑΙΑΣ',
    descr_en: 'AG. VASILEIOS - AG. SOFIA - KRAT. NIKAIAS',
    routes: ['2967', '2968'],
  },
  '1262': {
    ml: '294',
    line: '1262',
    sdcs: ['54'],
    id: '890',
    descr: 'ΣΤ. ΑΓ. ΜΑΡΙΝΑ - ΝΑΥΠΗΓΕΙΑ ΣΚΑΡΑΜΑΓΚΑ',
    descr_en: 'ST. AG. MARINA - NAFPIGIA SKARAMAGKA',
    routes: ['3043', '3044'],
  },
  '1264': {
    ml: '234',
    line: '1264',
    sdcs: ['54', '59', '60'],
    id: '790',
    descr: 'ΓΛΥΦΑΔΑ - ΠΕΡΙΣΤΕΡΙ (ΑΓ. ΑΝΤΩΝΙΟΣ) ΝΥΧΤΕΡΙΝΗ',
    descr_en: 'GLYFADA - PERISTERI (AG. ANTONIOS) (NIGHT LINE)',
    routes: ['3013', '3014'],
  },
}
